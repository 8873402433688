import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, finalize, map, takeUntil, throttleTime } from 'rxjs';
import { InvitedUserInfo, UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { RoastSchedule } from 'src/app/models/RoastSchedule';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { SchedulerPlannerComponent } from './scheduler-planner.component';
import { SplitAreaSize, SplitGutterInteractionEvent } from 'angular-split';
import { SchedulerInputComponent } from './scheduler-input.component';
import { SchedulerService } from './scheduler.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SchedulerPlannerFavComponent } from './scheduler-planner-fav.component';
import { Enumerations } from 'src/app/models/Enumerations';
import { StandardService } from 'src/app/util/services/standard.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NGXLogger } from 'ngx-logger';
import { Coffee } from 'src/app/models/Coffee';

@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit, OnDestroy {

    constructor(
        private userService: UserService,
        private schedulerService: SchedulerService,
        private standardService: StandardService,
        private breakpointObserver: BreakpointObserver,
        public utils: Utils,
        private route: ActivatedRoute,
        private router: Router,
        private logger: NGXLogger,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        // this.beansAndBlendsPlaceholder = `${this.tr.anslate('Beans')} / ${this.tr.anslate('Blends')}`;
    }

    readonly SHOW_PREVIOUS_DAYS = 1;
    readonly SHOW_NEXT_DAYS = 2;
    readonly MAX_SPLIT_SIZE = 500; // max height of input part in px for automatic setting
    readonly DEFAULT_SPLIT_SIZE = 279;

    lastScheduleCheck = DateTime.now().minus({days: 1});

    readonly checkTodayFun = () => {
        if (!this.today || (this.today.toISODate() !== DateTime.now().toISODate())) {
            this.today = DateTime.now();
            // browser window has been open since yesterday, reload
            this.loadSubscription.next({ date: this.today, useCache: false });
        }
        if (DateTime.now().diff(this.lastScheduleCheck, 'minutes').minutes > 4) {
            this.loadSubscription.next({ date: this.lastDate ?? DateTime.now(), useCache: false });
        }
    }

    lastDate: DateTime = DateTime.now();
    schedules: RoastSchedule[] = [];
    scheduleCache = new Map<string, RoastSchedule>();
    // 0, 1, ... number of rows (7 days in one row)
    scheduleRowsIndices = [0];

    isDataLoaded = false;
    isScheduleLoaded = false;

    defaultSplitSizes: SplitAreaSize[] = [this.DEFAULT_SPLIT_SIZE, '*'];
    splitSizes = this.defaultSplitSizes;
    readonly splitSizesLocalStorageName = 'planner-split-size';
    manualSplitSize = false;
    lastSInputSplitSize: number | '*';

    machineFilter: string[];
    machines: string[];

    // stores: Location[];
    // ssf: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';

    userFilter: InvitedUserInfo[];
    users: InvitedUserInfo[];

    loading = 0;
    loadingTimers: ReturnType<typeof setTimeout>[] = [];
    currentUser: UserType;
    readOnly = false;
    isDarkmode = false;

    includePlannedInStock: boolean;

    DateTime = DateTime;
    today = DateTime.now();
    mainUnit: UnitSystemType = 'kg';

    zoomState: 's' | 'm' | 'l' = 's';
    calendarWeeks = [];
    month: string;

    isSmall$: Observable<boolean>;
    isVerySmall$: Observable<boolean>;

    loadSubscription = new Subject<{ date: string | DateTime, useCache: boolean }>();
    private ngUnsubscribe = new Subject();

    @ViewChild('splitCanvas', { read: ElementRef }) canvasElementRef: ElementRef;

    @ViewChildren('schedulerPlanner') schedulerPlanners: QueryList<SchedulerPlannerComponent>;
    @ViewChildren('schedulerPlanner', { read: ElementRef }) plannerElementRefs: QueryList<ElementRef>;

    @ViewChild('schedulerInput') schedulerInput: SchedulerInputComponent;
    @ViewChild('schedulerInput', { read: ElementRef }) inputElementRef: ElementRef;

    @ViewChildren('schedulerPlannerFavorites') schedulerFavorites: QueryList<SchedulerPlannerFavComponent>;

    ngOnInit(): void {
        window.addEventListener("focus", this.checkTodayFun, false);

        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.readOnly = this.userService.isReadOnly();
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lb';
        }

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        const localSplit = localStorage.getItem(this.splitSizesLocalStorageName);
        if (localSplit) {
            this.splitSizes = JSON.parse(localSplit);
            this.manualSplitSize = true;
        } else {
            this.splitSizes = this.defaultSplitSizes;
        }

        this.isSmall$ = this.breakpointObserver.observe('(max-width: 650px)')
            .pipe(map(result => result.matches));
        this.isVerySmall$ = this.breakpointObserver.observe('(max-width: 480px)')
            .pipe(map(result => result.matches));
        this.useSettings();

        this.loadSubscription
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(val => {
                this.schedulerInput?.edit(undefined);
                let newDate: DateTime;
                if (!val?.date || typeof val.date === 'string') {
                    newDate = this.lastDate;
                } else {
                    newDate = val.date;
                }

                if (val.date === 'reload' || !val?.useCache) {
                    if (this.schedulerInput) {
                        // update beans and stock
                        this.schedulerInput.getAllBeansAndBlends();
                    } else if (val.date !== 'init') {
                        this.logger.error('could not update beans / stock in scheduler input');
                    }
                }

                if (this.zoomState === 'l') {
                    // TODO use cache
                    this.scheduleCache.clear();
                    this.lastScheduleCheck = DateTime.now();
                    // this propagates to the SchedulerPlannerYearComponent
                    this.lastDate = newDate;
                } else if (this.zoomState === 'm') {
                    // calc prevDays and nextDays such that the whole month is covered
                    const firstOfMonth = newDate.startOf('month');

                    const beginningOfMonthDays = firstOfMonth.localWeekday - 1;
                    const day = newDate.day;
                    const daysInMonth = newDate.daysInMonth;
                    const prevDays = beginningOfMonthDays + day - 1;

                    const lastOfMonth = newDate.endOf('month');
                    const endOfMonthDays = 7 - lastOfMonth.localWeekday;
                    const nextDays = daysInMonth - day + endOfMonthDays;

                    this.calendarWeeks = this.arrayRange(newDate.minus({ days: prevDays }).localWeekNumber, newDate.plus({ days: nextDays }).localWeekNumber);
                    if (val.date === 'reload' || !val?.useCache) {
                        this.scheduleCache.clear();
                        this.lastScheduleCheck = DateTime.now();
                        this.loadSchedule(newDate.toISODate(), prevDays, nextDays, undefined, prevDays, nextDays);
                    } else {
                        const cacheInfo = this.getNotCachedInfo(newDate.toISODate(), prevDays, nextDays);
                        if (cacheInfo) {
                            this.loadSchedule(cacheInfo.date, 0, cacheInfo.nextDays, newDate.toISODate(), prevDays, nextDays);
                        } else {
                            this.schedules = [];
                            // fill from cache
                            for (let d = -prevDays; d <= nextDays; d++) {
                                const curDate = newDate.plus({ days: d });
                                this.schedules.push(this.scheduleCache.get(curDate.toISODate()));
                            }
                            // call the method but have nothing loaded from the server
                            this.loadSchedule(newDate.toISODate(), null, null);
                        }
                    }
                } else { // zoomState === 's'
                    if (val.date === 'reload' || !val?.useCache) {
                        this.scheduleCache.clear();
                        this.lastScheduleCheck = DateTime.now();
                        this.loadSchedule(newDate?.toISODate());
                    } else {
                        const cacheInfo = this.getNotCachedInfo(newDate?.toISODate());
                        if (cacheInfo) {
                            this.loadSchedule(cacheInfo.date, 0, cacheInfo.nextDays, newDate?.toISODate());
                        } else {
                            this.schedules = [];
                            // fill from cache
                            for (let d = -this.SHOW_PREVIOUS_DAYS; d <= this.SHOW_NEXT_DAYS; d++) {
                                const curDate = newDate.plus({ days: d });
                                this.schedules.push(this.scheduleCache.get(curDate.toISODate()));
                            }
                            // call the method but have nothing loaded from the server
                            this.loadSchedule(newDate.toISODate(), null, null);
                        }
                    }
                }
            });

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && (e as NavigationEnd).url.indexOf('schedule') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next({ date: 'reload', useCache: false });
                }
            });

        if (this.currentUser) {
            this.loadSubscription.next({ date: 'init', useCache: false });
        }
    }

    ngOnDestroy(): void {
        window.removeEventListener('focus', this.checkTodayFun);
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
        this.loadingTimers.forEach(lt => clearTimeout(lt));
    }

    protected reloadSchedule(): void {
        this.schedulerInput?.edit(undefined);
        this.loadSubscription.next({ date: 'reload', useCache: false });
    }

    private loadSchedule(day?: string, prevDays?: number, nextDays?: number, realDate?: string, realPrev?: number, realNext?: number): void {
        if (!day) {
            day = DateTime.now().toISODate();
        }
        const theday = DateTime.fromISO(realDate ?? day);
        this.lastDate = theday;
        this.month = `${this.utils.getMonthStr(theday.month - 1)} ${theday.year}`;
        this.getRoastSchedule(day, prevDays, nextDays, realDate, realPrev, realNext);
        // need to set this.filteredItems in the planners
        // need to wait until the list of planners has been updated to match
        // the list of schedules
        setTimeout(() => {
            this.filterChanged();
        }, 0);
    }

    protected filterLabelObjects<T extends string>(search: string, objects: T[]): T[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(obj => (obj?.['label'] ?? obj)?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1);
    }

    protected filterUserObjects(search: string, objects: InvitedUserInfo[]): InvitedUserInfo[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(obj => obj.nickname?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1);
    }

    protected filterChanged(): void {
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            sp.setFilter(
                this.machineFilter?.length === this.machines?.length ? undefined : this.machineFilter,
                this.userFilter?.length === this.users?.length ? undefined : this.userFilter,
            );
        }
    }

    private arrayRange(start: number, stop: number, step = 1) {
        return Array.from(
            { length: (stop - start) / step + 1 },
            (_value, index) => start + index * step,
        );
    }

    setLoading(startOrEnd: -1 | 1): void {
        if (startOrEnd === 1) {
            this.loadingTimers.push(setTimeout(() => { this.loading += 1; }, 600));
        } else {
            clearTimeout(this.loadingTimers.shift());
            if (this.loading > 0) this.loading -= 1;
        }
    }

    private getNotCachedInfo(date: string, prevDays = this.SHOW_PREVIOUS_DAYS, nextDays = this.SHOW_NEXT_DAYS): { date: string, nextDays: number } {
        const thedate = DateTime.fromISO(date);
        let startDate: DateTime;
        let cnt = 0;
        let streakBroken = false;
        for (let d = -prevDays; d <= nextDays; d++) {
            const curDate = thedate.plus({ days: d });
            if (!this.scheduleCache.has(curDate.toISODate())) {
                if (!startDate) {
                    startDate = curDate;
                }
                if (streakBroken) {
                    // have 1 not in cache (startDate), then some in cache, then this one not in cache
                    // need to get all from the startDate
                    return { date: startDate.toISODate(), nextDays: nextDays + (thedate.diff(startDate, 'days').days) };
                }
                cnt += 1;
            } else if (startDate && !streakBroken) {
                streakBroken = true;
            }
        }
        if (cnt) {
            return { date: startDate.toISODate(), nextDays: cnt - 1 };
        }
        return undefined;
    }

    /**
     * Retrieves the schedule for the given date. Ignores and overwrites any cached items.
     * @param date the ISODate for which to retrieve the schedule
     * @param [prevDays=this.SHOW_PREVIOUS_DAYS] number of days before the given date to load
     * @param [nextDays=this.SHOW_NEXT_DAYS] number of days after the given date to load
     * @param [realPrev] used to fill the schedules from the cache
     * @param [realNext] used to fill the schedules from the cache
     */
    private getRoastSchedule(date: string, prevDays = this.SHOW_PREVIOUS_DAYS, nextDays = this.SHOW_NEXT_DAYS, realDate?: string, realPrev = this.SHOW_PREVIOUS_DAYS, realNext = this.SHOW_NEXT_DAYS): void {
        const thedate = DateTime.fromISO(realDate ?? date);

        if (prevDays != null || nextDays != null) {
            this.setLoading(1);
            this.schedulerService.getSchedule(date, prevDays || 0, nextDays || 0)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .pipe(finalize(() => {
                    this.setLoading(-1);
                    this.readOnly = this.userService.isReadOnly();
                }))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.schedules = [];
                            // fill cache
                            response.result.forEach(schedule => this.scheduleCache.set(schedule.date, schedule));
                            // fill from cache
                            for (let d = -realPrev; d <= realNext; d++) {
                                const curDate = thedate.plus({ days: d });
                                this.schedules.push(this.scheduleCache.get(curDate.toISODate()));
                            }
                            if (this.schedules.length < 7) {
                                this.scheduleRowsIndices = [0];
                            } else {
                                this.scheduleRowsIndices = Array.from(Array(Math.floor(this.schedules.length / 7)).keys());
                            }
                            // // make sure schedules array has all expected entries
                            // const nrSchedules = (this.SHOW_PREVIOUS_DAYS ?? 0) + (this.SHOW_NEXT_DAYS ?? 0) + 1;
                            // const curLen = this.schedules.length;
                            // for (let i = 0; i < nrSchedules - curLen; i++) {
                            //     const sched = new RoastSchedule();
                            //     sched.date = DateTime.now().plus({ days: i - this.SHOW_PREVIOUS_DAYS + curLen }).toISODate();
                            //     this.schedules.push(sched);
                            // }
                            this.isScheduleLoaded = true;
                            if (this.isDataLoaded) {
                                this.loadDataForSchedules();
                            }
                        } else {
                            this.utils.handleError('error retrieving the schedule', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving the schedule', error);
                    }
                });
        } else {
            // all in cache
            if (this.schedules.length < 7) {
                this.scheduleRowsIndices = [0];
            } else {
                this.scheduleRowsIndices = Array.from(Array(Math.floor(this.schedules.length / 7)).keys());
            }
        }
    }

    /**
     * Checks if the given user _id is already in the filter. If not, returns
     * the respective InvitedUserInfo from this.users if found.
     * @param userFilter current user filter to check
     * @param userId the user _id to find
     * @returns InvitedUserInfo of the user _id if not already in the filter; undefined otherwise
     */
    private missingUserInFilter(userFilter: InvitedUserInfo[], userId: string): InvitedUserInfo {
        for (const userInfo of userFilter) {
            if (userInfo?._id?.toString() === userId) {
                return undefined;
            }
        }
        for (const userInfo of this.users) {
            if (userInfo._id.toString() === userId) {
                return userInfo;
            }
        }
        return undefined;
    }

    protected onNewItem(data: { item: RoastScheduledItem, date?: string }): void {
        if (this.readOnly) { return; }

        const sps = this.schedulerPlanners?.toArray();
        const date = data?.date;

        if (this.machineFilter && data.item.machine && !this.machineFilter.includes(data.item.machine)) {
            // this.machineFilter.push(data.item.machine);
            this.machineFilter = [...this.machineFilter, data.item.machine];
        }
        if (!this.userFilter) {
            this.userFilter = [];
        }
        if (data.item.user) {
            const missingUser = this.missingUserInFilter(this.userFilter, data.item.user);
            if (missingUser) {
                // this.machineFilter.push(data.item.machine);
                this.userFilter = [...this.userFilter, missingUser];
            }
        }

        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            if ((date && date === sp?.getDate()) || (!date && sp?.isMainDay)) {
                // sp.setFilter(this.machineFilter, this.userFilter);
                sp.addItem(data.item);
                break;
            }
        }
    }

    protected coffeeStockChanged(coffees: Coffee[]): void {
        if (this.readOnly) { return; }

        const sps = this.schedulerPlanners?.toArray();
        for (const sp of sps ?? []) {
            sp?.updateCoffeesAndBlends(coffees);
        }
    }

    protected createTrigger(values: string[], narrow = true): string {
        if (narrow) {
            return values.map(v => v.substring(0, 6)).join(', ');
        }
        return values.join(', ');
    }

    protected createNarrowUserTrigger(users: InvitedUserInfo[]): string {
        return this.createTrigger(users.map(u => u.nickname));
    }

    protected movedAcrossDays(data: { fromDate: string; toDate: string; }) {
        if (this.readOnly) { return; }

        const sps = this.schedulerPlanners?.toArray();
        let found = 0;
        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            if (data.fromDate === sp?.getDate() || data.toDate === sp?.getDate()) {
                // sp.setFilter(this.machineFilter, this.userFilter);
                this.schedulerService.addSummary(sp.schedule, sp.filteredItems, this.mainUnit);
                found += 1;
                if (found === 2) {
                    break;
                }
            }
        }
    }

    protected onDragEnd(e: SplitGutterInteractionEvent): void {
        this.splitSizes = e.sizes;
        // save user-defined size only here since gutter was explicitly dragged
        localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
        this.manualSplitSize = true;
    }

    protected onGutterClick(e: SplitGutterInteractionEvent): void {
        if (e.sizes[1] !== '*' && e.sizes[1] <= 15) {
            const height = Math.min((this.inputElementRef?.nativeElement?.offsetHeight ?? this.DEFAULT_SPLIT_SIZE) + 25);
            const totalheight = Math.max(this.canvasElementRef?.nativeElement?.offsetHeight ?? 0, 2 * height);
            const perc = Math.round(height * 100 / totalheight);
            this.splitSizes = [100 - perc, perc];
        } else {
            this.splitSizes = [85, 15];
        }
        // don't store this as user defined
        // localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
        // "store" as not user defined, i.e. set back to auto mode
        localStorage.removeItem(this.splitSizesLocalStorageName);
    }

    protected updateSplitSize(): void {
        if (!this.manualSplitSize) {
            const height = Math.min((this.inputElementRef?.nativeElement?.offsetHeight ?? this.DEFAULT_SPLIT_SIZE) + 25);
            // console.log('Height: ' + height);
            setTimeout(() => {
                const totalheight = Math.max(this.canvasElementRef?.nativeElement?.offsetHeight ?? 0, 2 * height);
                // console.log('totalheight: ' + totalheight);
                const perc = Math.round(height * 100 / totalheight);
                // console.log('perc: ' + perc);
                this.splitSizes = [100 - perc, perc];
                // localStorage.setItem(this.splitSizesLocalStorageName, JSON.stringify(this.splitSizes));
            }, 0);
        }
    }

    private loadDataForSchedules(): void {
        if (this.zoomState === 'l') {
            return;
        }

        if (!this.schedulerInput) {
            setTimeout(() => {
                this.schedulerInput?.loadDataForSchedules(this.schedules);
            }, 150);
        } else {
            this.schedulerInput.loadDataForSchedules(this.schedules);
        }

        // this (esp. the setFilter) needs to be called after the automagic
        // call of the schedule setter in the planner
        setTimeout(() => {
            const sps = this.schedulerPlanners?.toArray();
            for (let s = 0; s < sps?.length; s++) {
                this.schedulerService.addInfoAndSummary(sps?.[s]?.schedule, sps?.[s]?.filteredItems, this.mainUnit, this.users?.length);
                sps?.[s]?.setFilter(
                    this.machineFilter?.length === this.machines?.length ? undefined : this.machineFilter,
                    this.userFilter?.length === this.users?.length ? undefined : this.userFilter,
                );
            }
        }, 150);
        // this.isDataLoaded = false;
        this.isScheduleLoaded = false;
    }

    protected dataLoaded(): void {
        this.isDataLoaded = true;
        if (this.isScheduleLoaded) {
            this.loadDataForSchedules();
        }
    }

    protected onCancelEdit(): void {
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            sps[s]?.deselect();
        }
        const sfs = this.schedulerFavorites?.toArray();
        for (let s = 0; s < sfs?.length; s++) {
            sfs[s]?.deselect();
        }
    }

    protected onSaveEdit(event: { item: RoastScheduledItem, cb: (success: boolean) => void, favoritesLine?: number }): void {
        if (this.readOnly) { return; }

        const editModeItemOriginal = event?.item;
        if (event.favoritesLine >= 0) {
            const sfs = this.schedulerFavorites?.toArray();
            // success = sfs.some(sf => sf.updateEditedItem(/*editModeItemOriginal*/));
            sfs[event.favoritesLine].updateEditedItem(/*editModeItemOriginal*/);
        } else {
            const sps = this.schedulerPlanners?.toArray();
            sps.forEach(sp => sp.updateEditedItem(editModeItemOriginal));
        }
        // let success = false;
        // for (let s = 0; s < sps?.length; s++) {
        //     success = success || sps[s]?.updateEditedItem(editModeItemOriginal);
        // }
        if (typeof event.cb === 'function') {
            event.cb(true);
        }
    }

    protected itemAdded(item: RoastScheduledItem): void {
        if (this.schedulerInput) {
            this.schedulerInput.itemAdded(item);
        } else {
            this.reloadSchedule();
        }
    }

    protected itemDeleted(item: RoastScheduledItem): void {
        if (this.schedulerInput) {
            this.schedulerInput.itemDeleted(item);
        } else {
            this.reloadSchedule();
        }
    }

    protected editItem(itemInfo: { item: RoastScheduledItem, readonly?: boolean, favoritesLine?: number }, schIdx: number): void {
        this.schedulerInput?.edit(itemInfo.item, itemInfo.readonly, itemInfo.favoritesLine);

        // deselect all others
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            if (s !== schIdx) {
                sps[s]?.deselect(false);
            }
        }
        const sfs = this.schedulerFavorites?.toArray();
        for (let s = 0; s < sfs?.length; s++) {
            if (s !== itemInfo.favoritesLine) {
                sfs[s]?.deselect();
            }
        }
    }

    protected updateFinished(): void {
        this.schedulerInput?.updateFinished();
    }

    protected machinesLoaded(machines: string[]): void {
        this.machines = machines ?? [];
        // use "all" as default since UI cannot set machineFilter to undefined
        // which means the case !machineFilter could never be achieved
        this.machineFilter = this.machines?.slice();
        // this.machineFilter = undefined;
    }

    protected usersLoaded(users: InvitedUserInfo[]): void {
        this.users = users ?? [];
        this.userFilter = this.users?.slice();
    }

    // // protected storesLoaded(stores: Location[]): void {
    // //     this.stores = stores ?? [];
    // //     this.ssf = this.utils.readShowStockFrom(this.stores, this.currentUser);
    // // }

    // // called from the template when the dropdown value changed
    // showstockfromChanged($event: { value: { _id: string, hr_id?: string, label?: string }[] | 'all' }): void {
    //     if (!$event.value?.length || $event.value.length === this.stores.length) {
    //         $event.value = 'all';
    //     }
    //     this.ssf = $event.value;
    //     this.utils.storeShowStockFrom($event.value, this.currentUser);
    // }

    // showstockfromOpened(opened: boolean): void {
    //     if (!opened && !this.ssf?.length) {
    //         // nothing selected, change to 'all' for dropdown
    //         // delete this.ssf;
    //         this.ssf = 'all';
    //     }
    // }

    protected addCurrentItem(date: string): void {
        if (this.readOnly) { return; }

        // ensure input component is visible
        if (this.splitSizes[1] !== '*' && this.splitSizes[1] <= 25) {
            const height = Math.min((this.inputElementRef?.nativeElement?.offsetHeight || this.DEFAULT_SPLIT_SIZE) + 25, this.DEFAULT_SPLIT_SIZE + 25);
            const totalheight = Math.max(this.canvasElementRef?.nativeElement?.offsetHeight || 0, 2 * height);
            const perc = Math.round(height * 100 / totalheight);
            this.splitSizes = [100 - perc, perc];
        }

        // deselect all others
        const sps = this.schedulerPlanners?.toArray();
        for (let s = 0; s < sps?.length; s++) {
            const sp = sps[s];
            if (sp.getDate() !== date) {
                sp?.deselect();
            } else {
                // ensure new item is visible
                this.plannerElementRefs.get(s).nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }
        // this will, if successful, select the new item
        this.schedulerInput?.addCurrent(date);
    }

    protected scrollTo(date: DateTime) {
        this.month = `${this.utils.getMonthStr(date.month - 1)} ${date.year}`;
        this.loadSubscription.next({ date, useCache: true });
    }

    protected scrollDay(dir: -1 | 0 | 1 | MatDatepickerInputEvent<DateTime>) {
        // this.edit(this.lastEditItem, true, true);
        if (dir === 0) {
            this.scrollTo(DateTime.now());
            return;
        } else if (dir === -1 || dir === 1) {
            const day = this.lastDate;
            let otherDay: DateTime;
            if (this.zoomState === 's') {
                otherDay = day.plus({ day: dir });
            } else if (this.zoomState === 'm') {
                otherDay = day.plus({ month: dir });
            } else if (this.zoomState === 'l') {
                otherDay = day.plus({ month: dir });
            }
            this.scrollTo(otherDay);
            return;
        }
        if (dir?.value?.isValid) {
            this.scrollTo(dir.value);
            return;
        }
        this.scrollTo(DateTime.now());
    }

    // protected scrollMax(dir: -1 | 1) {
    //     // this.edit(this.lastEditItem, true, true);
    //     this.scrollTo(dir === -1 ? 'start' : 'end');
    // }

    protected zoom(to: false | true | 's' | 'm' | 'l'): void {
        const oldZoomState = this.zoomState;
        if (to === false) {
            // zoom out
            this.zoomState = this.zoomState === 'l' ? 'm' : 's';
        } else if (to === true) {
            this.zoomState = this.zoomState === 's' ? 'm' : 'l';
        } else {
            this.zoomState = to;
        }
        if (oldZoomState !== this.zoomState) {
            if (this.zoomState === 'm' || this.zoomState === 'l') {
                if (this.splitSizes[1] !== 15) {
                    this.lastSInputSplitSize = this.splitSizes[1];
                }
                if (this.splitSizes[1] === '*' || this.splitSizes[1] > 15) {
                    this.splitSizes = [85, 15];
                }
            } else {
                if (this.lastSInputSplitSize !== 15) {
                    if (typeof this.lastSInputSplitSize === 'number') {
                        this.splitSizes = [100 - this.lastSInputSplitSize, this.lastSInputSplitSize];
                    } else {
                        this.splitSizes = [85, 15];
                    }
                }
                this.lastSInputSplitSize = undefined;
            }
            this.loadSubscription.next({ date: undefined, useCache: true });
        }
    }

    protected getWeekNr(date: string) {
        return DateTime.fromISO(date).localWeekNumber;
    }

    private useSettings(): void {
        this.includePlannedInStock = ['1', 'true'].includes(this.currentUser.account?.settings?.pref_stockinclplanned?.toString()) ? true : false;
    }

    protected includePlannedInStockChanged(): void {
        if (!this.readOnly) {
            this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.pref_stockinclplanned, this.includePlannedInStock, this.ngUnsubscribe) ?? this.currentUser;
        }
    }
}
