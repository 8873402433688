<div style="margin-left: 10px;">
    <h2 i18n="external$$button and heading, import beans data from external web site@@Import Beans Data" class="external-heading" data-test="h2_heading">Import Beans Data</h2>
    @if (!haveDataBeans) {
    <div class="form-wrapper" [ngStyle]="{'align-items': specificSource ? 'center' : 'flex-end'}">
        @if (specificSource) {
        <div class="dropdown-text-image">
            <img class="dropdown-image" [src]="'assets/images/suppliers/' + source.partner + (isDarkmode ? '_dark' : '') + '_70x110.png'" alt="a picture of the supplier">
        </div>
        } @else {
        <mat-form-field
            class="max-width-field select-with-logo" subscriptSizing="fixed">
            <mat-select name="source"
                panelWidth="null" panelClass="show-all-options" hideSingleSelectionIndicator="true"
                [value]="source"
                [compareWith]="utils.comparePartnersFn"
                (selectionChange)="getIDsForSource($event.value)">
                @if (source) {
                <mat-select-trigger>
                    <div class="dropdown-text-image">{{source?.label}}<img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + source?.partner + (isDarkmode ? '_dark' : '') + '_70x110.' + (source?.useSVG ? 'svg' : 'png')" onerror="this.style.display='none'" alt="a picture of the supplier"></div>
                </mat-select-trigger>
                }
                @for (src of AddExternalComponent.SUPPLIERS; track src?.label) {
                <mat-option [value]="src">
                    <div class="dropdown-text-image">{{src.label}}<img class="dropdown-image" width="55px" height="35px" [src]="'assets/images/suppliers/' + src.partner + (isDarkmode ? '_dark' : '') + '_70x110.' + (src.useSVG ? 'svg' : 'png')" onerror="this.style.display='none'" alt="a picture of the supplier">
                    </div>
                </mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        <span class="grow-field-with-spinner">
            <mat-form-field>
                <!-- (blur)="checkID($event.target['value'])" -->
                <input matInput type="text" id="id" name="id"
                    placeholder="ID" i18n-placeholder="generic$$ID@@ID"
                    [ngModel]="id"
                    (input)="changeIDFilter($event.target['value'])"
                    (focus)="changeIDFilter($event.target['value'])"
                    [matAutocomplete]="autoCompleteID">
                <mat-hint align="start">{{idHint}}</mat-hint>
                <mat-autocomplete #autoCompleteID="matAutocomplete"
                    hideSingleSelectionIndicator="true"
                    (optionSelected)="checkID(undefined, $event)">
                    @for (id of ids; track id) {
                    <mat-option [value]="id">{{id}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
            @if (isRetrievingIds) {
            <mat-spinner diameter="15" style="margin-right: 15px; width: 20px"></mat-spinner>
            }
        </span>
        <button [disabled]="isRetrieving || !source || !id" (click)="retrieveData()" mat-raised-button
            class="action-button reload-button" data-test="bttn_retrieve">
            <mat-icon>sync</mat-icon>
        </button>
    </div>
    }
    <br />

    @if (!beans) {
    @if (isRetrieving || haveDataBeans) {
    <p>
        <mat-spinner class="spinner"></mat-spinner>
    </p>
    }
    } @else { <!-- if beans-->
    @if (isNew !== -1) {
    <div i18n="external$$heading, save imported beans data@@Do you want to add the following beans?">Do you want to add the following beans?</div>
    }

    @if (!current && source?.label && updated_at) {
    <div style="margin: 10px 0 20px 0;">
        <strong i18n="external$$last seen at supplier on date@@last seen at supplier on date">Last seen at {{source?.label}} on {{updated_at.toLocaleString(DateTime.DATE_MED)}}</strong>
    </div>
    }

    @if (existingId) {
    <div style="margin: 10px 0 20px 0;">
        <strong i18n="external$$beans already exist will merge attributes@@beans already exist will merge attributes" data-test="strng_warn_merge">These beans already exist in your account. New attributes will be merged.</strong>
    </div>
    }

    <app-coffee
        [coffee]="beans"
        [dontShowCloneDelete]="true"
        [index]="0"
        [showHidden]="false"
        [allCertifications]="allCertifications"
        idToHighlight="-1"
        [editMode]="0"
        [isNew]="isNew"
        [fields]="fields"
        [stores]="stores"
        [properties]="properties"
        [producers]="producers"
        [suppliers]="suppliers"
        [initialSupplier]="source"
        [allVarietals]="allVarietals"
        [allVarietalCategories]="allVarietalCategories"
        [newFieldData]="newFieldData"
        [newProducerData]="newProducerData"
        [regions]="regions"
        [customExternalRegions]="customExternalRegions"
        [readOnly]="!currentUser"
        showstockfrom="all"
        (uniqueLabelChanged)="haveDataBeans ? uniqueLabelChanged($event) : undefined"
        (editCancelled)="editCancelled()"
        (saved)="saved()"
        [existingId]="existingId"
        special="import">
    </app-coffee>
    }
</div>