import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, forkJoin } from 'rxjs';
import { finalize, takeUntil, throttleTime } from 'rxjs/operators';
import { Certification } from 'src/app/models/Certification';
import { Coffee } from 'src/app/models/Coffee';
import { Location } from 'src/app/models/Location';
import { Producer } from 'src/app/models/Producer';
import { Supplier } from 'src/app/models/Supplier';
import { Variety } from 'src/app/models/Variety';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { PropertiesType, Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { ExternalService } from 'src/app/modules/external/external.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { APBeans } from 'src/app/models/protobuf/generated/beans_pb';
import { Buffer } from 'buffer/';
import { StandardService } from 'src/app/util/services/standard.service';
import { PropertiesService } from 'src/app/util/services/properties.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { Property } from 'src/app/models/Property';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-add-external',
    templateUrl: './add-external.component.html',
    styleUrls: ['./add-external.component.scss']
})
export class AddExternalComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private externalService: ExternalService,
        private standardService: StandardService,
        protected utils: Utils,
        private userService: UserService,
        private serverLogService: ServerLogService,
        private propertiesService: PropertiesService,
    ) { }

    static MIN_SUPPLIERS: Partial<SupplierPartner>[] = [
        // { label: 'Sweet Maria\'s', partner: 'sweetmarias', supplierid: undefined, _id: undefined },
        // { label: 'Coffee Shrub', partner: 'coffeeshrub', supplierid: undefined, _id: undefined },
        // { label: 'Cafe Imports', partner: 'cafeimports', supplierid: undefined, _id: undefined },
        { label: 'Algrano', partner: 'algrano', supplierid: undefined, _id: undefined },
        { label: 'Royal Coffee', partner: 'royalcoffee', supplierid: undefined, _id: undefined },
        { label: 'Genuine Origin', partner: 'genuineorigin', supplierid: undefined, _id: undefined },
        // { label: 'Showroom Coffee', partner: 'showroomcoffee', supplierid: undefined, _id: undefined },
    ];
    // static SUPPLIERS = AddExternalComponent.MIN_SUPPLIERS;
    DEFAULT_SOURCE = AddExternalComponent.MIN_SUPPLIERS[0];
    static SUPPLIERS: Partial<SupplierPartner>[] = [];

    AddExternalComponent = AddExternalComponent;
    DateTime = DateTime;

    allIDs: string[] = [];
    ids: string[] = [];
    idHint: string;
    current: boolean;
    updated_at: DateTime;
    existingId: string;

    source: Partial<SupplierPartner>;
    id: string;
    lastId: string;
    isNew = 0;
    beans: Coffee;
    // if an imported coffee has location (field) info, need to add it as new field
    newFieldData: Location;
    // if an imported coffee has producer info, need to add it as new producer
    newProducerData: Producer;

    allCertifications: Certification[];
    fields: Location[];
    stores: Location[];
    showstockfrom: { _id: string, hr_id?: string, label?: string }[] | 'all' = 'all';
    producers: Producer[];
    suppliers: Supplier[];
    allVarietals: Variety[][];
    allVarietalCategories: string[] = []; // ok to init with []; template uses allVarietals
    // label is translated cat ('Schatten'), value is actual value ('Forest')
    properties: PropertiesType;
    regions: string[];
    // any regions that came in with the protobuf data but are not in the valid regions list for the current user
    customExternalRegions: string[];

    haveDataBeans: APBeans;
    returnUrl: string;

    isRetrieving = false;
    isRetrievingIds = false;

    isDarkmode = false;

    // for demo purposes
    specificSource: Partial<SupplierPartner>;

    // coff: Coffee = {
     
    //     _id: undefined, 'default_unit': { 'name': Enumerations.CoffeeUnits.BAG, 'size': 60 }, 'low_limit': -1, 'defects_unit': 350, 'ICO': { 'origin': 15 }, 'label': 'Imported from Sweet Maria\'s', 'origin': 'Sumatra', 'origin_region': 'Asia', 'processing': 'Dry#natural', 'cultivation': 'Shade-grown#Garden', 'ageing': 'barrel - aged', 'decaffeination': 'Organic Solvent#Triglyceride Process', 'drying': 'mechanical dryer', 'harvesting': 'manual stripping', 'packaging': 'GrainPro / Ecotact', 'species': 'Charrieriana' };

    currentUser: UserType;
    private ngUnsubscribe = new Subject();

    JSON = JSON;

    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm)
            );

        this.specificSource = undefined;

        // put code in here to be able to start fresh after user hits cancel
        // even after a protobuf import took place
        this.route.queryParams.subscribe(params => {
            const haveData = params?.data ? decodeURIComponent(params.data) : undefined;
            if (haveData) {
                // protobuf data received
                try {
                    this.returnUrl = this.route.snapshot.queryParams.returnUrl ? decodeURIComponent(this.route.snapshot.queryParams.returnUrl) : undefined;

                    const uint8arr = new Uint8Array(Buffer.from(haveData, 'base64'));
                    this.haveDataBeans = APBeans.deserializeBinary(uint8arr);

                    this.loadAllProperties(() => {
                        this.beans = this.utils.convertPBBeansToBeans(this.haveDataBeans, this.properties, this.allCertifications);

                        // check whether beans with this label already exist (will be overwritten)
                        // => existingId
                        this.uniqueLabelChanged();

                        if (this.beans?.producer) {
                            // protobuf import: replace producer with _id if found (by label)
                            let found = false;
                            for (const producer of this.producers) {
                                if (producer.label?.toLocaleUpperCase() === this.beans.producer.label?.toLocaleUpperCase()) {
                                    this.beans.producer = producer;
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                // need to use "add new producer mode"
                                this.newProducerData = this.beans.producer;
                                this.beans.producer = '######' as unknown as Producer;
                            }
                        } else {
                            this.newProducerData = undefined;
                        }

                        if (this.currentUser && this.beans.origin) {
                            this.utils.loadRegionsForOrigin(this.beans.origin, this.ngUnsubscribe, (regions: string[]) => {
                                // cannot use the normal process of treating custom regions (prepend in regions array)
                                // since the coffee.component doesn't know which custom regions to save as properties
                                // const customRegions = this.beans.regions?.filter(reg => regions?.indexOf(reg) < 0)?.sort();
                                // if (customRegions?.length) {
                                //     // find if there are any custom entries in the retrieved regions
                                //     if (regions.indexOf('') < 0) {
                                //         regions.unshift('');
                                //     }
                                //     regions.unshift(...customRegions);
                                // }
                                this.customExternalRegions = this.beans.regions?.filter(reg => regions?.indexOf(reg) < 0)?.sort();
                                for (let r = 0; r < this.customExternalRegions?.length; r++) {
                                    const addRegion = this.customExternalRegions[r];
                                    regions.unshift(addRegion);
                                }
                                this.regions = regions;
                            });
                        } else {
                            this.regions = [];
                        }

                        if (this.beans?.location) {
                            // protobuf import: replace field location with _id if found (by label)
                            let found = false;
                            this.newFieldData = undefined;
                            for (const field of this.fields) {
                                if (field.label === this.beans.location.label) {
                                    this.beans.location = field;
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                // need to use "add new field mode"
                                this.newFieldData = this.beans.location;
                                this.beans.location = '######' as unknown as Location;
                            }
                        }
                    });
                } catch (error) {
                    this.utils.handleError('error reading data - please ask for a new link', error);
                    this.serverLogService.errorOccurred(new Error(`error in add-external when parsing protobuf data: ${error.message}: ${error.stack}`), 'AddExternalComponent.ngOnInit')
                        .pipe(throttleTime(environment.RELOADTHROTTLE))
                        .subscribe();
                }
            } else {
                this.haveDataBeans = undefined;
                this.externalService.getSupplierPartners()
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                if (response.result.length) {
                                    AddExternalComponent.SUPPLIERS = response.result;

                                    let found = false;
                                    for (const src of AddExternalComponent.SUPPLIERS) {
                                        // if the username equals a supplier partner name, use this
                                        if (this.currentUser?.nickname.toUpperCase() === src.label.toUpperCase()) {
                                            this.specificSource = src;
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        this.DEFAULT_SOURCE = AddExternalComponent.SUPPLIERS[0];
                                    }
                                } else {
                                    AddExternalComponent.SUPPLIERS = AddExternalComponent.MIN_SUPPLIERS;
                                    this.DEFAULT_SOURCE = AddExternalComponent.MIN_SUPPLIERS[0];
                                }
                                if (this.specificSource) {
                                    this.source = this.specificSource;
                                } else if (this.route.snapshot.params.source) {
                                    let found = false;
                                    for (const src of AddExternalComponent.SUPPLIERS) {
                                        if (src.partner === this.route.snapshot.params.source) {
                                            this.source = src;
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        this.source = this.DEFAULT_SOURCE;
                                    }
                                } else {
                                    this.source = this.DEFAULT_SOURCE;
                                }
                                this.id = this.route.snapshot.params.id;

                                if (this.source) {
                                    // we explicitly treat an id 0 as invalid
                                    if (!this.id) {
                                        this.getIDsForSource(this.source);
                                    } else {
                                        this.retrieveData();
                                    }
                                }
                            } else {
                                AddExternalComponent.SUPPLIERS = AddExternalComponent.MIN_SUPPLIERS;
                                this.utils.handleError('error, please try again later');
                                this.serverLogService.errorOccurred(new Error(`error in externalService.getSupplierPartners: ${JSON.stringify(response)}`), 'AddExternalComponent.ngOnInit2')
                                    .pipe(throttleTime(environment.RELOADTHROTTLE))
                                    .subscribe();
                            }
                        },
                        error: error => {
                            AddExternalComponent.SUPPLIERS = AddExternalComponent.MIN_SUPPLIERS;
                            this.utils.handleError('error, please try again later', error);
                            const errStrUp = (error.error['message'] || error.error['error'] || error.error)?.toString().toUpperCase();
                            if (error?.status !== 401 && errStrUp.indexOf('JWT EXPIRED') < 0) { // don't report expired login etc.
                                this.serverLogService.errorOccurred(new Error(`error in externalService.getSupplierPartners: ${error.message}: ${error.stack}`), 'AddExternalComponent.ngOnInit3')
                                    .pipe(throttleTime(environment.RELOADTHROTTLE))
                                    .subscribe();
                            }
                        },
                    });
            }
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    // check whether beans with this label already exist (will be overwritten)
    // => existingId
    // needs to be done after every change of the label as well!
    uniqueLabelChanged(change?: { prop: string, value: string }, cb?: () => void): void {
        if (change) {
            if (change.prop === 'pickedYear') {
                if (!this.beans.crop_date) {
                    this.beans.crop_date = { picked: [Number.parseInt(change.value, 10)] };
                } else if (!this.beans.crop_date.picked) {
                    this.beans.crop_date.picked = [Number.parseInt(change.value, 10)];
                } else {
                    this.beans.crop_date.picked[0] = Number.parseInt(change.value, 10);
                }
            } else {
                // label, origin
                this.beans[change.prop] = change.value;
            }
        }
        this.existingId = undefined;
        this.standardService.getAll<Coffee>('coffees', {
            label: this.beans.label,
            origins: { vals: [this.beans.origin?.['origin'] ?? this.beans.origin] },
            pickedYear: this.beans.crop_date?.picked?.[0] ?? null,
        })
            .pipe(
                throttleTime(environment.RELOADTHROTTLE),
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    // this is called both on success and error
                    if (typeof cb === 'function') {
                        cb();
                    }
                }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result?.length) {
                            // coffee already exists
                            this.existingId = response.result[0]._id;
                        } else {
                            // coffee does not exist
                            this.existingId = undefined;
                        }
                    } else {
                        this.utils.handleError('error retrieving information', response.error);
                    }
                },
                error: error => {
                    if (error.status === 404) {
                        // coffee does not exist
                        this.existingId = undefined;
                    } else {
                        this.utils.handleError('error retrieving information', error);
                    }
                }
            });
    }

    editCancelled(): void {
        this.beans = undefined;
        this.id = undefined;
        this.isNew = 0;
        if (this.haveDataBeans) {
            this.haveDataBeans = undefined;
            if (this.returnUrl) {
                // this.router.navigate(['/coffees', { 'id': 'C1059' }]);
                const splt = this.returnUrl.split(';');
                if (splt.length === 2) {
                    const path = splt[0];
                    const param = splt[1];
                    const psplt = param.split('=');
                    if (psplt.length === 2) {
                        this.router.navigate([path, { [psplt[0]]: decodeURIComponent(psplt[1]) }]);
                    } else {
                        this.router.navigate([this.returnUrl]);
                    }
                } else {
                    this.router.navigate([this.returnUrl]);
                }
            } else {
                this.router.navigate(['/add']);
            }
        }
    }

    saved(): void {
        this.isNew = -1;
    }

    // getText(value: string): string {
    //     for (let s = 0; s < AddExternalComponent.SUPPLIERS.length; s++) {
    //         const src = AddExternalComponent.SUPPLIERS[s];
    //         if (src.partner === value) {
    //             return src.label;
    //         }
    //     }
    //     return value;
    // }

    getIDsForSource(sup: Partial<SupplierPartner>): void {
        if (!sup) {
            return;
        }
        this.source = sup;
        this.id = undefined;
        this.lastId = undefined;
        this.isRetrievingIds = true;
        this.externalService.retrieveIDs(sup.partner)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.allIDs = response.result;
                    this.idHint = undefined;
                    if (this.allIDs?.length) {
                        // TODO filter those already at the server
                        // show first 3 ids; but don't show entries that differ only in case
                        // (which can happen if an entry has "id" and "sku")
                        const hints = [this.allIDs[0]];
                        let i = 1;
                        while (i < this.allIDs.length && this.allIDs[i].toUpperCase() === hints[0].toUpperCase()) {
                            i += 1;
                        }
                        if (i < this.allIDs.length) {
                            hints.push(this.allIDs[i]);
                            i += 1;
                            while (i < this.allIDs.length && (this.allIDs[i].toUpperCase() === hints[0].toUpperCase() || this.allIDs[i].toUpperCase() === hints[1].toUpperCase())) {
                                i += 1;
                            }
                            if (i < this.allIDs.length) {
                                hints.push(this.allIDs[i]);
                            }
                        }
                        this.idHint = [...hints, '...'].join(', ');
                        // this.idHint = [...this.allIDs.slice(0, 3), '...'].join(', '); //`${this.allIDs[0]}, ...`;
                    }
                    this.isRetrievingIds = false;
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                    this.allIDs = undefined;
                    this.idHint = undefined;
                    this.isRetrievingIds = false;
                },
            });
    }

    changeIDFilter(id: string): void {
        if (!id) {
            this.ids = [];
            this.id = '';
            return;
        }
        if (this.allIDs) {
            const cId = id.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\n/g, '').replace(/\s\s+/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '').replace(/–/g, '-');
            this.ids = this.allIDs.filter(myid => myid && myid.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(cId) >= 0);
        }
        this.id = id.trim();
    }

    checkID(id: string, event?: MatAutocompleteSelectedEvent): void {
        this.id = (event?.option?.value || id)?.trim() || '';
        this.id = this.id.replace(/\n/g, '').replace(/\s\s+/g, ' ').replace(/^\s+/g, '').replace(/\s+$/g, '').replace(/–/g, '-');
        if (!this.id) {
            return;
        }
        if (this.allIDs && !this.allIDs.includes(this.id)) {
            this.id = undefined;
        } else if (this.lastId !== this.id) {
            this.retrieveData();
        }
    }

    /**
     * Load all properties for editing.
     * Includes producers, suppliers, locations, certifications, varietals
     * properties (for coffee, producer)
     */
    loadAllProperties(cb?: () => void): void {
        const obs: Observable<{ success: boolean; result: unknown[]; count?: number; v?: string; error: string; }>[] = [];
        obs.push(this.standardService.getAll<Producer>('producers').pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe)));
        obs.push(this.standardService.getAll<Supplier>('suppliers').pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe)));
        obs.push(this.standardService.getAll<Location>('locations').pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe)));
        obs.push(this.propertiesService.getCertifications().pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe)));
        obs.push(this.propertiesService.getVerietals().pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe)));
        obs.push(this.propertiesService.getProperties(['coffee', 'producer'], undefined).pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe)));

        forkJoin(obs).subscribe(results => {
            if (results?.length === 6) {
                // producers
                if (results[0]?.success === true) {
                    this.producers = results[0].result as Producer[];
                } else {
                    this.utils.handleError('error retrieving information');
                }

                // suppliers
                if (results[1]?.success === true) {
                    this.suppliers = results[1].result as Supplier[];
                } else {
                    this.utils.handleError('error retrieving information');
                }

                // locations
                if (results[2]?.success === true) {
                    // TODO optimize: only retrieve places of types FIELD or STORE
                    const places = results[2].result as Location[];
                    this.fields = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.FIELD) >= 0);
                    this.fields.sort((p1, p2) => p1.internal_hr_id - p2.internal_hr_id);
                    this.stores = places.filter(p => (p.type || p['__t'] || '').indexOf(Enumerations.LocationTypes.STORE) >= 0);
                    this.stores.sort((p1, p2) => p2.internal_hr_id - p1.internal_hr_id);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                } else {
                    this.utils.handleError('error retrieving all locations');
                }

                // certifications
                if (results[3]?.success === true) {
                    this.allCertifications = results[3].result as Certification[];
                    this.allCertifications.sort((c1, c2) => {
                        if (c1.label < c2.label) { return -1; }
                        if (c1.label > c2.label) { return 1; }
                        return 0;
                    });
                } else {
                    this.utils.handleError('error retrieving information');
                }

                // varietals
                if (results[4]?.success === true) {
                    this.allVarietalCategories = [];
                    this.allVarietals = [];
                    const vars = results[4].result as Variety[];
                    for (const varietal of vars) {
                        let idx = this.allVarietalCategories.indexOf(varietal.category);
                        if (idx < 0) {
                            idx = this.allVarietalCategories.length;
                            this.allVarietalCategories.push(varietal.category);
                        }
                        if (typeof this.allVarietals[idx] === 'undefined') {
                            this.allVarietals[idx] = [];
                        }
                        this.allVarietals[idx].push(varietal);
                    }
                } else {
                    this.utils.handleError('error retrieving information');
                }

                // properties
                if (results[5]?.success === true) {
                    this.properties = this.utils.convertLoadedProps(results[5].result as Property[]);
                } else {
                    this.utils.handleError('error retrieving information');
                }

                if (typeof cb === 'function') {
                    cb();
                }
            } else {
                this.utils.handleError('error retrieving information');
            }
        });
    }

    retrieveData(): void {
        if (!this.id) {
            return;
        }
        this.lastId = this.id;
        this.isRetrieving = true;
        this.beans = undefined;
        this.current = false;
        this.updated_at = undefined;
        this.externalService.retrieveData(this.source.partner, this.id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    const resp = response.result;
                    if (resp?.coffee) {
                        this.current = resp.current;
                        this.updated_at = DateTime.fromISO(resp.updated_at);
                        this.existingId = resp.existingId;
                        this.beans = resp.coffee;
                        this.beans.imported = {
                            source: this.source.partner,
                            useSVG: this.source.useSVG,
                            id: this.id,
                            link: resp.source,
                            sourceLabel: this.source.label,
                            date: DateTime.now(),
                        };
                        this.beans.hidden = false;
                        if (this.currentUser && this.beans.origin) {
                            this.utils.loadRegionsForOrigin(this.beans.origin, this.ngUnsubscribe, (regions: string[]) => this.regions = regions);
                        } else {
                            this.regions = [];
                        }
                        this.loadAllProperties(() => {
                            if (this.beans.producer) {
                                // protobuf import: replace producer with _id if found (by label)
                                let found = false;
                                for (const producer of this.producers) {
                                    if (producer.label?.toLocaleUpperCase() === this.beans.producer.label?.toLocaleUpperCase()) {
                                        this.beans.producer = producer; //._id as unknown as Producer;
                                        found = true;
                                        break;
                                    }
                                }
                                if (!found) {
                                    // need to use "add new producer mode"
                                    this.newProducerData = this.beans.producer;
                                    this.beans.producer = '######' as unknown as Producer;
                                }
                                // clone such that the coffee.component retrieves the new data
                                this.beans = Object.assign({}, this.beans);
                            } else {
                                this.newProducerData = undefined;
                            }
                        });
                    }
                    this.isRetrieving = false;
                    this.isNew = 0;
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                    this.isRetrieving = false;
                },
            });
    }
}
