import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, finalize, forkJoin, takeUntil, throttleTime } from 'rxjs';
import { Blend } from 'src/app/models/Blend';
import { Coffee } from 'src/app/models/Coffee';
import { Enumerations } from 'src/app/models/Enumerations';
import { InvitedUserInfo, UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { StandardService } from 'src/app/util/services/standard.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { environment } from 'src/environments/environment';
import { RemindersService } from '../reminders/reminder.service';
import { NGXLogger } from 'ngx-logger';
import { RoastSchedule } from 'src/app/models/RoastSchedule';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { Roast, RoastTemplate } from 'src/app/models/Roast';
import { Location } from 'src/app/models/Location';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { RenameMachineNamesDialogComponent } from '../reminders/renamemachinenames-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/util/alert/alert.service';
import { TextinputDialogComponent } from '../ui/dialog/textinput-dialog.component';
import { MatFormField, MatLabel, MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { DateTime } from 'luxon';
import { ChipsFilterComponent } from './chips-filter.component';
import { BeansSearchDialogComponent } from '../ui/dialog/beans-search-dialog.component';
import { OrganicIconComponent } from '../ui/organicicon.component';
import { DecimalPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatInput } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatButton, MatIconButton } from '@angular/material/button';
import { TemplateSearchDialogComponent } from '../ui/dialog/template-search-dialog.component';
import { Constants } from 'src/app/util/constants';
import { MatTooltip } from '@angular/material/tooltip';
import { SchedulerService } from './scheduler.service';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckbox } from '@angular/material/checkbox';

interface AveragePerMachine { losses: Map<string, number>, batchsizes: Map<string, number>, rsizes: Map<string, number> }
type ChangedVar = 'losses' | 'loss' | 'unitamount' | 'unitweight' | 'batchsize' | 'batches' | 'averages' | 'template';

@Component({
    selector: 'app-scheduler-input',
    templateUrl: './scheduler-input.component.html',
    styleUrls: ['./scheduler-input.component.scss'],
     
    imports: [OrganicIconComponent, NgClass, NgStyle, MatIcon, ChipsFilterComponent, MatFormField, MatLabel, MatSelect, FormsModule, MatSelectTrigger, MatOption, DecimalPipe, MatProgressSpinner, CdkTextareaAutosize, NgTemplateOutlet, MatInput, NgxMatSelectSearchModule, MatButton, MatTooltip, MatAutocomplete, MatAutocompleteTrigger, MatCheckbox, MatIconButton],
    standalone: true,
})
export class SchedulerInputComponent implements OnInit, OnDestroy {

    constructor(
        private userService: UserService,
        private standardService: StandardService,
        private remindersService: RemindersService,
        private schedulerService: SchedulerService,
        public utils: Utils,
        private serverLogService: ServerLogService,
        public tr: TranslatorService,
        private logger: NGXLogger,
        private dialog: MatDialog,
        private alertService: AlertService,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        // this.beansAndBlendsPlaceholder = `${this.tr.anslate('Beans')} / ${this.tr.anslate('Blends')}`;
    }

    readonly DEFAULT_LOSS = 15;
    readonly SUGGESTION_OFF_THRESHOLD = 0.05;
    readonly SHOW_PREVIOUS_DAYS = 2;
    readonly SHOW_NEXT_DAYS = 1;

    @Input() currentUser: UserType = undefined;
    @Input() readOnly: boolean;
    _includePlannedInStock: boolean = undefined;
    @Input() set includePlannedInStock(ipin: boolean) {
        // avoid that getAllBeansAndBlends is called multiple times
        if (ipin == null) {
            return;
        }
        if (this._includePlannedInStock !== ipin) {
            if (this._includePlannedInStock != null) {
                this._includePlannedInStock = ipin;
                this.getAllBeansAndBlends();
            } else {
                this._includePlannedInStock = ipin;
            }
        } else {
            this._includePlannedInStock = ipin;
        }
    }
    get includePlannedInStock(): boolean {
        return this._includePlannedInStock;
    }
    @Output() newItem = new EventEmitter<{ item: RoastScheduledItem, date?: string }>();
    @Output() renderFinished = new EventEmitter<void>();
    @Output() inputFinished = new EventEmitter<void>();
    @Output() cancelEdit = new EventEmitter<void>();
    // sends original (before edit)
    @Output() saveEdit = new EventEmitter<{ item: RoastScheduledItem, cb: (success: boolean) => void, favoritesLine?: number }>();
    @Output() reloadSchedule = new EventEmitter<void>();
    @Output() machinesLoaded = new EventEmitter<string[]>();
    @Output() usersLoaded = new EventEmitter<InvitedUserInfo[]>();
    // @Output() storesLoaded = new EventEmitter<Location[]>();
    @Output() coffeeStockChanged = new EventEmitter<Coffee[]>();

    editModeItem: RoastScheduledItem;
    editModeItemOriginal: RoastScheduledItem;
    currentlySettingItem = false;
    itemReadOnly: boolean;
    favoritesLine: number;

    machines: string[] = [];
    filteredMachines: string[] = [];
    machine: string;
    machinesToRenameCount = 0;

    templates: ((RoastTemplate | Roast) & { roast_id?: string })[] = [];
    filteredTemplates: ((RoastTemplate | Roast) & { roast_id?: string })[] = [];
    template: (RoastTemplate | Roast) & { roast_id?: string };
    alreadyLoadedTemplates = new Set<string>();
    // ensure this template is in the list displayed to the user (e.g. when editing)
    mustIncludeTemplate: (RoastTemplate | Roast) & { roast_id?: string };

    suggestedBatchsize: number;
    batchsize: number;
    // upd July 24: never automatically change batch size
    allowBatchsizeChanges = false;

    suggestedLoss: number;
    loss: number;
    allowLossChanges = true;

    coffees: Coffee[];
    filteredCoffees: Coffee[];
    additionalCoffee: Coffee;
    coffee: Coffee;
    haveFilteredCoffee = false;

    unitamount: number;
    // suggestedAmount: number;
    allowAmountChanges = true;

    unitweight: number;
    allowWeightChanges = true;
    batches = 1;

    weightFormat = '1.0-3';

    blends: Blend[] = [];
    filteredBlends: Blend[] = [];
    additionalBlend: Blend;
    blend: Blend;
    haveFilteredBlends = false;
    isPostBlend: boolean;

    selectedTitle: (RoastScheduledItem & { end_weight: number });
    allRecentRoasts: (RoastScheduledItem & { end_weight: number })[];
    recentRoasts: (RoastScheduledItem & { end_weight: number })[];

    users: InvitedUserInfo[];
    userNicknames: string[];
    filteredUsers: InvitedUserInfo[] = [];
    user: InvitedUserInfo;

    stores: Location[] = [];
    filteredStores: Location[] = [];
    store: Location;
    storesLabels: string[] = [];

    coffeeStockStrs: string[] = [];
    coffeeStockWarns: boolean[] = [];

    notes: string;
    title: string;
    showTII = false;
    allowTitleChanges = true;

    alreadyCalledCalculateStock = false;

    isUpdating = false;

    // key: coffee / blend _id
    averages = new Map<string, AveragePerMachine>();
    // key: coffee / blend label/_id; value: all templates (independent of machine)
    templateMap = new Map<string, ((RoastTemplate | Roast) & { roast_id?: string })[]>();

    _loading = 0;
    set loading(v: number) {
        this._loading = v;
        if (this._loading === 0) {
            this.renderFinished.emit();
            this.inputFinished.emit();
            this.useSettings();
            this.calculateStock();
        }
        if (this._loading < 0) {
            this._loading = 0;
        }
    }
    get loading(): number {
        return this._loading;
    }

    @ViewChild('machineFilter') machineFilter: ChipsFilterComponent;
    @ViewChild('storeFilter') storeFilter: ChipsFilterComponent;
    @ViewChild('userFilter') userFilter: ChipsFilterComponent;
    @ViewChild('templateSelect') templateSelect: MatSelect;
    @ViewChild('titleTrigger') titleTrigger: MatAutocompleteTrigger;

    @ViewChild('titleFormField') set titleElem(elem: unknown) {
        if (elem) {
            this.renderFinished.emit();
        }
    }

    waitingForChanges = false;

    mainUnit: UnitSystemType = 'kg';
    loadSubscription = new Subject<string>();
    private ngUnsubscribe = new Subject();

    Math = Math;
    Number = Number;
    EPSILON = Constants.EPSILON;
    readonly today = DateTime.now().toISODate();

    ngOnInit(): void {
        if (this.currentUser) {
            const settings = this.currentUser.account?.settings;
            if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
                this.mainUnit = 'lb';
                if (settings?.schedule_unit === 'oz') {
                    this.mainUnit = settings.schedule_unit;
                } else if (settings?.schedule_unit !== 'lb' && !this.readOnly) {
                    this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.schedule_unit, this.mainUnit, this.ngUnsubscribe) ?? this.currentUser;
                }
            } else {
                if (settings?.schedule_unit === 'g') {
                    this.mainUnit = settings.schedule_unit;
                } else if (settings?.schedule_unit !== 'kg' && !this.readOnly) {
                    this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.schedule_unit, this.mainUnit, this.ngUnsubscribe) ?? this.currentUser;
                }
            }
        }

        this.loadSubscription
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.loadAll();
            });

        this.router.events
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((e: unknown) => {
                if (e instanceof NavigationEnd && (e as NavigationEnd).url.indexOf('reminders') >= 0) {
                    // only pass and debounce interesting events
                    this.loadSubscription.next('reload');
                }
            });

        if (this.currentUser) {
            this.loadSubscription.next('init');
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    private loadAll(): void {
        this.getAllBeansAndBlends();
        this.getAllMachines();
        this.getAllUsers();
        this.getAllStores();
        this.getAllRecentRoasts();
    }

    private getAllUsers(): void {
        this.loading += 1;
        this.userService.getAdditionalInfo('OTHERUSERS')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { setTimeout(() => { this.loading -= 1; this.usersLoaded.emit(this.users); }, 50); }))
            // .pipe(finalize(() => { this.loading -= 1; this.usersLoaded.emit(this.users); }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.users = response.result.otherUsers;
                        // just for test
                        // this.users = [...this.users, ...this.users];
                        // this.users = [];

                        // add current users (never contained in otherUsers)
                        this.users.unshift({ _id: this.currentUser.user_id?.toString(), nickname: this.currentUser.nickname });
                    } else {
                        this.utils.handleError('user data could not be downloaded', response.error);
                        if (!this.users?.length) {
                            this.users = [];
                        }
                    }
                    this.filteredUsers = this.users.slice();
                    this.userNicknames = this.users.map(u => u.nickname);
                    // this.userNicknames = [null, ...this.users.map(u => u.nickname)];
                },
                error: error => {
                    this.utils.handleError('user data could not be downloaded', error);
                    if (!this.users?.length) {
                        this.users = [];
                    }
                    this.filteredUsers = this.users.slice();
                    this.userNicknames = [null, ...this.users.map(u => u.nickname)];
                }
            });
    }

    private getAllRecentRoasts(): void {
        this.loading += 1;
        this.schedulerService.getRecentRoasts()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { setTimeout(() => { this.loading -= 1 }, 50); }))
            // .pipe(finalize(() => { this.loading -= 1; }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.allRecentRoasts = response.result;
                        this.recentRoasts = this.allRecentRoasts.slice();
                    } else {
                        this.logger.error('could not getAllRecentRoasts', response);
                        // fail silently
                        this.allRecentRoasts = [];
                        this.recentRoasts = [];
                    }
                },
                error: err => {
                    // fail silently
                    this.allRecentRoasts = [];
                    this.recentRoasts = [];
                    this.logger.error('could not getAllRecentRoasts', err);
                }
            });
    }

    changeTitleFilter(): void {
        if (!this.title) {
            this.recentRoasts = this.allRecentRoasts;
            return;
        }
        if (this.allRecentRoasts) {
            const filterValue = this.title?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.recentRoasts = this.allRecentRoasts.filter(myItem => myItem?.title && myItem.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
        }
    }

    private getAllStores(): void {
        this.loading += 1;
        this.standardService.getAll<Location>('stores')
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => {
                // set those in calculateStock for better sorting:
                // this.filteredStores = this.stores.slice();
                // this.storesLabels = this.stores.map(s => s.label);
                setTimeout(() => { this.loading -= 1; });
                // this.loading -= 1;
            }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.stores = response.result?.map(s => ({ _id: s._id, hr_id: s.hr_id, label: s.label })) ?? [];
                    } else {
                        this.utils.handleError('error retrieving all stores', response.error);
                        if (!this.stores?.length) {
                            this.stores = [];
                        }
                    }
                    this.stores.sort((s1, s2) => (s1?.label ?? '').localeCompare(s2?.label ?? ''));
                },
                error: error => {
                    this.utils.handleError('error retrieving all stores', error);
                    if (!this.stores?.length) {
                        this.stores = [];
                    }
                }
            });
    }

    private changeBeansStock(beansInfos: { coffee: string, stock: { location: string, amount: number }[] }[], add = false) {
        // remove or add planned amounts from or to beans stock
        const changedCoffees = [];
        for (const beansInfo of beansInfos) {
            const cof = beansInfo.coffee;
            for (const exCof of this.coffees) {
                if (exCof.hr_id?.toString() === cof) {
                    changedCoffees.push(exCof);
                    const negStocks = beansInfo.stock;
                    for (let stockIdx = 0; stockIdx < exCof.stock?.length; stockIdx++) {
                        const exStock = exCof.stock[stockIdx];
                        for (let sIdx = 0; sIdx < negStocks?.length; sIdx++) {
                            const negStock = negStocks[sIdx];
                            if (negStock?.location === exStock?.location?.hr_id) {
                                exStock.amount += (add ? 1 : -1) * (negStock.amount || 0);
                                // TODO remove
                                // console.log(`changeBeansStock: ${add ? 'adding' : 'removing'} ${negStock.amount}kg from ${exCof.label} at ${exStock.location.label}`);
                                break;
                            }
                        }
                    }
                    break;
                }
            }
        }
        if (changedCoffees.length) {
            this.coffeeStockChanged.emit(changedCoffees);
        }
    }

    public getAllBeansAndBlends(): void {
        this.loading += 1;
        this.standardService.getStocked(false)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { if (!this.includePlannedInStock) setTimeout(() => { this.loading -= 1; }, 50); }))
            // .pipe(finalize(() => this.loading -= 1))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.coffees = response.result.coffees ?? [];
                        this.blends = response.result.blends ?? [];

                        // #500 Stock in scheduler should not take replacement coffees into account
                        // this.blends = this.blends.concat(response.result.replBlends ?? [])

                        // filteredCoffees will be set in (after) call to calculateStock
                        this.coffees.forEach(cof => {
                            cof.yearLabel = this.utils.createBeansYearLabel(cof);
                            delete cof.crop_date;
                            cof.stock?.forEach(stock => {
                                // convert from artisan representation
                                stock.location = {
                                    _id: stock.location_id,
                                    label: stock.location_label,
                                    hr_id: stock.location_hr_id,
                                };
                                delete stock.location_id;
                                delete stock.location_label;
                                delete stock.location_hr_id;
                            });
                        });
                        let notFoundCoffeeIds = [];
                        this.blends?.forEach(blend => {
                            const nfci = this.utils.populateCoffees(blend, this.coffees);
                            if (nfci?.length) {
                                notFoundCoffeeIds = notFoundCoffeeIds.concat(nfci);
                            } else {
                                blend.organic = this.utils.isOrganicBlend(blend);
                                blend.blendinfo = this.utils.getBlendStr(blend, ', ', true);
                            }
                        });
                        if (notFoundCoffeeIds.length) {
                            // could not populate some ingredients
                            this.loading += 1;
                            this.standardService.getAll<Coffee>('coffees', { dbID: { vals: notFoundCoffeeIds }, showHidden: 'true' })
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                .pipe(finalize(() => { this.loading -= 1; }))
                                .subscribe({
                                    next: response => {
                                        if (response.success === true) {
                                            this.coffees = this.coffees.concat(response.result ?? []);
                                            this.blends?.forEach(blend => {
                                                const nfci = this.utils.populateCoffees(blend, this.coffees);
                                                if (nfci?.length) {
                                                    // could not populate some ingredients even after getting the coffees
                                                    // fail silently; the consequence is mostly that the stock is wrongly
                                                    // calculated
                                                     
                                                    this.logger.error(`getAllBeansAndBlends: tried to populate blend, retrieved ingredient but still missing at least one coffee; details: blend: ${JSON.stringify(blend)}, nfci: ${JSON.stringify(nfci)}, SchedulerInputComponent.getAllBeansAndBlends`);
                                                     
                                                    this.serverLogService.sendError({ message: 'getAllBeansAndBlends: tried to populate blend, retrieved ingredient but still missing at least one coffee', details: `blend: ${JSON.stringify(blend)}, nfci: ${JSON.stringify(nfci)}` }, 'SchedulerInputComponent.getAllBeansAndBlends');
                                                }
                                                blend.organic = this.utils.isOrganicBlend(blend);
                                                blend.blendinfo = this.utils.getBlendStr(blend, ', ', true);
                                            });
                                        } else {
                                            this.utils.handleError('error retrieving all beans', response.error);
                                        }
                                    },
                                    error: error => {
                                        this.utils.handleError('error retrieving all beans', error);
                                    },
                                });
                        }

                        if (this.includePlannedInStock) {
                            this.loading += 1;
                            this.schedulerService.getFuturePlanned(DateTime.now().toISODate())
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                                // have -2 here since also the +1 from getStocked above is reset in this case
                                .pipe(finalize(() => { setTimeout(() => { this.loading -= 2; }, 50); }))
                                // .pipe(finalize(() => { this.loading -= 1; }))
                                .subscribe({
                                    next: response => {
                                        if (response.success === true) {
                                            this.changeBeansStock(response.result);
                                            // // mainly to update this.coffee / this.blend
                                            // this.calculateStock();
                                        } else {
                                            this.utils.handleError('error retrieving all beans', response.error);
                                        }
                                    },
                                    error: error => {
                                        this.utils.handleError('error retrieving all beans', error);
                                    },
                                });
                        } else {
                            // // mainly to update this.coffee / this.blend
                            // this.calculateStock();
                            // probably need to update schedule item coffees in the planner component
                            if (this.coffees.length) {
                                this.coffeeStockChanged.emit(this.coffees);
                            }
                        }
                    } else {
                        this.utils.handleError('error retrieving all beans', response.error);
                        if (this.includePlannedInStock) {
                            this.loading -= 1;
                        }
                    }
                },
                error: error => {
                    this.utils.handleError('error retrieving all beans', error);
                    if (this.includePlannedInStock) {
                        this.loading -= 1;
                    }
                },
            });
    }

    private getAllMachines(): void {
        this.loading += 1;
        this.remindersService.getAllMachines()
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { setTimeout(() => { this.loading -= 1; this.machinesLoaded.emit(this.machines); }, 50); }))
            // .pipe(finalize(() => { this.loading -= 1; this.machinesLoaded.emit(this.machines); }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (response.result?.length) {
                            this.machines = response.result?.map(mplusc => mplusc.label) ?? [];
                            // // just for test
                            // // this.machines = [];
                            // this.machines = [...this.machines, ...this.machines, ...this.machines];
                        } else {
                            this.machines = [];
                        }
                        this.machinesToRenameCount = (response.result ?? []).filter(m => m.cnt).length;
                    } else {
                        this.utils.handleError('error retrieving all machines', response.error);
                        if (!this.machines?.length) {
                            this.machines = [];
                        }
                    }
                    // this.machines.unshift(null);
                    this.filteredMachines = this.machines.slice();
                },
                error: error => {
                    this.utils.handleError('error retrieving all machines', error);
                    if (!this.machines?.length) {
                        this.machines = [];
                    }
                    // if (this.machines[0] !== null) {
                    //     this.machines.unshift(null);
                    // }
                    this.filteredMachines = this.machines.slice();
                }
            });
    }

    private useSettings(): void {
        if (!this.machine) {
            this.machine = decodeURIComponent(this.currentUser.account?.settings?.schedule_machine);
            if (this.machine === 'undefined') {
                this.machine = undefined;
            } else if (this.machineFilter && this.machine && this.machines.includes(this.machine)) {
                this.machineFilter.setValues([this.machine]);
            }
        }

        if (!this.store) {
            const storedStore = this.currentUser.account?.settings?.schedule_store;
            if (storedStore) {
                for (const str of this.stores) {
                    if (str._id?.toString() === storedStore) {
                        this.store = str;
                    }
                }
                if (this.store && this.storeFilter) {
                    this.storeFilter?.setValues([this.store?.label]);
                }
            }
        }
    }

    private calculateStock(whileEditing = false): void {
        if (this.loading) {
            return;
        }
        this.alreadyCalledCalculateStock = true;

        if (!this.store) {
            // none stored in settings, pick one
            this.store = this.stores?.[0];
            if (!this.readOnly) {
                this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.schedule_store, this.store?._id?.toString(), this.ngUnsubscribe) ?? this.currentUser;
            }
        }

        // const showstockfrom = this.ssf;
        const showstockfrom: 'all' | string[] = this.store ? [this.store._id?.toString()] : 'all';
        for (let c = 0; c < this.coffees?.length; c++) {
            const cof = this.coffees[c];
            // cof should have been populated; be on the safe side here
            if (typeof cof !== 'string') {
                const stk = this.utils.getCoffeeStock(cof, showstockfrom);
                cof.totalStock = stk;
                cof.totalStockStr = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                if (this.coffee?._id === cof._id) {
                    // update to update the totalStockStr in the dropdown
                    this.coffee = cof;
                }
            }
        }
        this.coffees?.sort((c1, c2) => (c2.totalStock ?? 0) - (c1.totalStock ?? 0) || (c1.label ?? '').localeCompare(c2.label ?? ''));
        if (!whileEditing && !this.coffee && !this.blend) {
            this.coffee = this.coffees?.[0];
            this.beansChanged({ value: { _id: this.coffee?._id?.toString() } });
        }

        for (let b = 0; b < this.blends?.length; b++) {
            const blnd = this.blends[b];
            if (blnd && typeof blnd === 'object') {
                // #500 Stock in scheduler should not take replacement coffees into account
                // const stk = this.utils.getBlendStock(blnd, showstockfrom, true);
                const stk = this.utils.getBlendStock(blnd, showstockfrom, false);
                blnd.totalStock = stk;
                blnd.totalStockStr = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                if (this.blend?._id === blnd._id) {
                    // update to update the totalStockStr in the dropdown
                    this.blend = blnd;
                }
            }
        }
        this.blends?.sort((b1, b2) => (b2.totalStock ?? 0) - (b1.totalStock ?? 0) || (b1.label ?? '').localeCompare(b2.label ?? ''));
        if (!whileEditing && !this.coffee && !this.blend) {
            this.blend = this.blends?.[0];
        }

        if (this.stores?.length) {
            for (const store of this.stores) {
                let stk: number;
                if (this.coffee) {
                    stk = this.utils.getCoffeeStock(this.coffee, [store._id?.toString()]);
                } else if (this.blend) {
                    // #500 Stock in scheduler should not take replacement coffees into account
                    // stk = this.utils.getBlendStock(this.blend, [store._id?.toString()], true);
                    stk = this.utils.getBlendStock(this.blend, [store._id?.toString()], false);
                } else if (this.coffees) {
                    stk = this.utils.getStoreStock(this.coffees, store._id?.toString());
                }
                store.coffeeStock = stk;
                store.coffeeStockStr = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                // this.coffeeStockStrs[c] = store.coffeeStockStr;
                // this.coffeeStockWarns[c] = !store.coffeeStock || this.unitamount > store.coffeeStock;
            }
            this.stores.sort((s1, s2) => (s2.coffeeStock ?? 0) - (s1.coffeeStock ?? 0) || (s1.label ?? '').localeCompare(s2.label ?? ''));
            this.filteredStores = this.stores.slice();
            this.storesLabels = this.stores.map(s => s.label);
            for (let c = 0; c < this.stores.length; c++) {
                const store = this.stores[c];
                this.coffeeStockStrs[c] = store.coffeeStockStr;
                this.coffeeStockWarns[c] = !store.coffeeStock || this.unitamount > store.coffeeStock;
            }

            if (this.store) {
                setTimeout(() => {
                    // (re)set to have the correct one selected
                    this.storeFilter?.setValues([this.store?.label]);
                }, 100);

                if (!whileEditing) {
                    // don't remove 
                    this.filteredCoffees = this.coffees?.filter(cof => cof.totalStock > 0) ?? [];
                }
                this.haveFilteredCoffee = this.filteredCoffees.length < (this.coffees?.length || 0);
                // if (this.additionalCoffee) {
                //     this.filteredCoffees.push(this.additionalCoffee);
                // }
                if (this.coffee && !this.filteredCoffees.some(cof => cof.hr_id === this.coffee.hr_id)) {
                    if (!this.coffees?.some(cof => cof.hr_id === this.coffee.hr_id)) {
                        if (!this.coffees) {
                            this.coffees = [this.coffee];
                        } else {
                            this.coffees.push(this.coffee);
                        }
                    }
                    this.filteredCoffees.push(this.coffee);
                }
                if (!whileEditing) {
                    this.filteredBlends = this.blends.filter(blend => blend.totalStock > 0);
                }
                this.haveFilteredBlends = this.filteredBlends.length < this.blends.length;
                if (this.blend && !this.filteredBlends.some(blend => blend.label === this.blend.label)) {
                    if (!this.blends.some(blend => blend.label === this.blend.label)) {
                        this.blends.push(this.blend);
                    }
                    this.filteredBlends.push(this.blend);
                }
                // if (this.additionalBlend) {
                //     this.filteredBlends.push(this.additionalBlend);
                // }
            }
        }
    }

    protected filterUsers(search: string, objects: InvitedUserInfo[]): InvitedUserInfo[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(user => user && (user.nickname?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1 || user.email?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1));
    }

    protected filterLabelObjects<T extends string | RoastTemplate | Roast | Location>(search: string, objects: T[]): T[] {
        if (!objects) {
            return;
        }
        if (!search) {
            return objects;
        }
        search = search.toLocaleLowerCase(this.locale);
        return objects.filter(obj => (obj?.['label'] ?? obj)?.toLocaleLowerCase(this.locale)?.indexOf(search) > -1);
    }

    protected beansChanged(changeEvent: { value: { _id: string } }, isBlend = false, calcStock = true): void {
        if (this.currentlySettingItem) {
            // don't mess with values while setting an item for edit
            return;
        }

        this.template = undefined;

        if (changeEvent?.value?._id) {
            if (isBlend) {
                this.coffee = undefined;
            } else {
                this.blend = undefined;
            }
            if (!this.coffee && !this.blend) {
                this.templates = [];
                this.filteredTemplates = [];
            }
            // this is done in getAverages
            // if (this.coffee || this.blend) {
            //     this.getRoastTemplates(this.coffee, this.blend, this.machine);
            // } else {
            //     this.templates = [];
            //     this.filteredTemplates = [];
            // }
            this.allowBatchsizeChanges = false;
            this.allowLossChanges = true;
            this.allowAmountChanges = true;
            this.allowWeightChanges = true;
            this.allowTitleChanges = true;
            this.alreadyCalledCalculateStock = false;
            this.getAverages(this.coffee, this.blend, this.machine, this.currentlySettingItem);
        } else {
            this.templates = [];
            this.filteredTemplates = [];
        }
        if (calcStock && !this.alreadyCalledCalculateStock) {
            this.calculateStock();
            this.alreadyCalledCalculateStock = false;
        }

        this.updateTitle();
    }

    protected machineChanged(useValues?: boolean, values?: string[]): void {
        if (this.currentlySettingItem) {
            // don't mess with values while setting an item for edit
            return;
        }

        if (useValues) {
            this.machine = values?.[0];
        }

        if (!this.readOnly) {
            this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.schedule_machine, this.machine, this.ngUnsubscribe) ?? this.currentUser;
            if (this.currentUser?.account) {
                if (!this.currentUser.account.settings) {
                    if (this.machine != null && this.machine !== 'undefined') {
                        this.currentUser.account.settings = { [Enumerations.SETTINGS.schedule_machine]: this.machine };
                    }
                } else {
                    if (this.machine != null && this.machine !== 'undefined') {
                        this.currentUser.account.settings[Enumerations.SETTINGS.schedule_machine] = this.machine;
                    } else {
                        delete this.currentUser.account.settings[Enumerations.SETTINGS.schedule_machine];
                    }
                }
            }
        }

        if (this.coffee || this.blend) {
            this.updateValues('averages');
            this.updateBatches();

            // #486: don't delete template
            // } else {
            //     this.templates = [];
            //     this.filteredTemplates = [];

            this.getRoastTemplates(this.coffee, this.blend, this.machine);
        }
    }

    protected storeChanged(useValue?: boolean, storeLabel?: string): void {
        if (this.currentlySettingItem) {
            // don't mess with values while setting an item for edit
            return;
        }

        if (useValue) {
            this.store = undefined;
            for (const store of this.stores) {
                if (store?.label === storeLabel) {
                    this.store = store;
                    break;
                }
            }
        }

        if (!this.readOnly) {
            this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.schedule_store, this.store?._id?.toString(), this.ngUnsubscribe) ?? this.currentUser;
        }

        this.calculateStock();
    }

    protected templateChanged(): void {
        if (this.currentlySettingItem) {
            // don't mess with values while setting an item for edit
            return;
        }

        this.updateValues('template');
    }

    private findObject<T extends { _id?: string, label?: string }>(objs: T[], objId: string, name?: 'blend' | 'coffee'): T {
        if (!objId) {
            return undefined;
        }
        for (const obj of objs) {
            if (obj._id?.toString() === objId || obj.label === objId) {
                if (name === 'blend') {
                    if (!this.filteredBlends.some(blend => blend.label === objId || blend._id?.toString() === objId)) {
                        this.filteredBlends.push(obj as Blend);
                    }
                } else if (name === 'coffee') {
                    if (!this.filteredCoffees.some(coffee => coffee._id?.toString() === objId)) {
                        this.filteredCoffees.push(obj as Coffee);
                    }
                }
                return obj;
            }
        }

        // not found
        if (name) {
            const showstockfrom: 'all' | string[] = this.store ? [this.store._id?.toString()] : 'all';
            if (name === 'blend') {
                this.standardService.getLabelled<T>(`${name}s`, objId)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.blend = response.result as Blend;
                                this.blend.organic = this.utils.isOrganicBlend(this.blend);
                                this.blend.blendinfo = this.utils.getBlendStr(this.blend, ', ', true);
                                // #500 Stock in scheduler should not take replacement coffees into account
                                // const stk = this.utils.getBlendStock(this.blend, showstockfrom, true);
                                const stk = this.utils.getBlendStock(this.blend, showstockfrom, false);
                                this.blend.totalStock = stk;
                                this.blend.totalStockStr = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                                this.blends.unshift(this.blend);
                                this.filteredBlends = this.blends.slice();
                            }
                        }
                    });
            } else {
                this.standardService.getOne<Coffee>('coffees', objId)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                this.coffee = response.result;
                                this.coffee.yearLabel = this.utils.createBeansYearLabel(this.coffee);
                                delete this.coffee.crop_date;
                                this.coffee.stock?.forEach(stock => {
                                    // convert from artisan representation
                                    stock.location = { _id: stock.location_id, label: stock.location_label };
                                    delete stock.location_id;
                                    delete stock.location_label;
                                });
                                const stk = this.utils.getCoffeeStock(this.coffee, showstockfrom);
                                this.coffee.totalStock = stk;
                                this.coffee.totalStockStr = this.utils.formatAmount(stk, undefined, this.currentUser?.unit_system, 1);
                            }
                        }
                    });
            }
        }
        return undefined;
    }

    protected titleChanged(predefinedSelected = false): void {
        if (this.currentlySettingItem) {
            // don't mess with values while setting an item for edit
            return;
        }

        if (predefinedSelected) {
            for (const rec of this.recentRoasts) {
                if (rec.title === this.title) {
                    this.cancelEditMode();

                    this.unitamount = rec.amount;
                    if (this.unitamount) {
                        this.batchsize = (this.batches || 1) * this.unitamount;
                        this.suggestedBatchsize = this.batchsize;
                    }
                    this.unitweight = rec.end_weight;
                    this.loss = rec.amount ? (rec.amount - rec.end_weight) / rec.amount * 100 : 15;
                    this.suggestedLoss = this.loss;
                    this.coffee = this.findObject(this.coffees, rec.coffee?.toString(), 'coffee');
                    this.blend = this.findObject(this.blends, rec.blend?.label, 'blend');
                    this.user = this.findObject(this.users, rec.user);
                    this.store = this.findObject(this.stores, rec.location?.toString());

                    this.template = rec.template;
                    if (this.template) {
                        if (!this.templates.map(t => t.roast_id).includes(this.template.roast_id)) {
                            this.templates.unshift(this.template);
                        }
                    }
                    this.filteredTemplates = this.templates.slice();

                    this.machine = rec.machine;
                    if (this.machineFilter) {
                        if (this.machine && this.machines.includes(this.machine)) {
                            this.machineFilter.setValues([this.machine]);
                        } else {
                            this.machineFilter.reset();
                        }
                    }
                    break;
                }
            }
        }

        this.allowTitleChanges = false;
    }

    private updateTitle(): void {
        if (!this.allowTitleChanges || this.currentlySettingItem) {
            return;
        }
        if (this.template) {
            this.title = this.template.label;
        } else if (this.coffee) {
            this.title = `${this.tr.anslate(this.coffee.origin)}${this.coffee.yearLabel ? ' ' : ''}${this.coffee.yearLabel}`;
            this.title += `${this.title ? ', ' : ''}${this.coffee.label}`;
        } else if (this.blend) {
            this.title = this.blend.label;
        }
    }

    // protected updateBatches(nrBatchesChanged = false, updateUnitAmount = true): void {
    protected updateBatches(nrBatchesChanged = false, unitAmountChanged = false): void {
        if (this.currentlySettingItem) {
            // don't mess with values while setting an item for edit
            return;
        }

        if (nrBatchesChanged) {
            if (this.batchsize && this.allowAmountChanges) {
                this.unitamount = this.batches * this.batchsize;
                this.updateValues('unitamount', false, false);
            }
        }

        if (this.unitamount) {
            if (this.batchsize && !nrBatchesChanged) {
                if (unitAmountChanged) {
                    this.batches = Math.ceil(Math.round(this.unitamount * 1000 / this.batchsize) / 1000);
                    // // if (updateUnitAmount) {
                    // // update amount to match (might be off bc. of the Math.ceil)
                    // this.unitamount = this.batches * this.batchsize;
                    // this.calculateStock();
                    // // }
                } else if (this.batches) {
                    this.unitamount = this.batches * this.batchsize;
                    this.updateValues('unitamount', false, false);
                }
            } else if (this.batches && !this.batchsize) {
                this.batchsize = this.unitamount / this.batches;
            }
        }

        if (!this.unitamount || nrBatchesChanged) {
            if (this.batchsize && this.batches) {
                this.unitamount = this.batches * this.batchsize;
                this.updateValues('unitamount', false, false);
                if (this.unitweight && !this.loss) {
                    this.loss = 100 - (this.unitweight / this.unitamount * 100);
                    // } else if (!this.unitweight && this.loss) {
                } else if (this.loss) {
                    this.unitweight = this.unitamount * (100 - this.loss) / 100.0;
                }
            }
        }
    }

    /**
     * Updates values depending on which input changed
     * @param {ChangedVar} changedVar the variable (group) which changed
     * @param {boolean} [onlyUpdateSuggestions=false] if true, only suggestions will be calculated, real values will not be changed
     */
    private updateValues(changedVar: ChangedVar, onlyUpdateSuggestions = false, updateBatches = true): void {
        let found = false;
        let batchsizes: AveragePerMachine['batchsizes'];
        switch (changedVar) {
            case 'template':
                if (this.template) {
                    if (this.template.end_weight && (this.template['start_weight'] || this.template['amount'])) {
                        const theloss = Math.round(1000 - (this.template.end_weight / (this.template['start_weight'] ?? this.template['amount']) * 1000)) / 10;
                        this.suggestedLoss = theloss;
                        if (this.allowLossChanges && !onlyUpdateSuggestions) {
                            this.loss = theloss;
                            // this.updateValues('losses');
                        }
                    }
                    if (this.template['start_weight'] || this.template['amount']) {
                        // if (this.allowAmountChanges && !onlyUpdateSuggestions) {
                        //     this.unitamount = this.template['start_weight'] ?? this.template['amount'];
                        //     // update weight if loss is also set; update loss if weight is also set
                        //     this.updateValues('unitamount');
                        // }
                        this.suggestedBatchsize = this.template['start_weight'] ?? this.template['amount'];
                        if (this.allowBatchsizeChanges && !onlyUpdateSuggestions) {
                            this.batchsize = this.suggestedBatchsize;
                            if (updateBatches) {
                                this.updateBatches();
                            }
                        }
                    } else {
                        // potentially update amount / weight / loss if one is missing
                        this.updateValues('loss');
                    }
                    if (this.template.machine && this.machines.includes(this.template.machine) && !onlyUpdateSuggestions) {
                        this.machine = this.template.machine;
                        // updateBatches is called in updateValues
                        // we use the values from the template, not the averages
                        this.updateValues('averages', true);
                    }
                }
                this.updateTitle();
                break;
            case 'averages':
                this.suggestedBatchsize = null;
                batchsizes = this.averages.get(this.coffee?._id || this.blend?.label)?.batchsizes;
                if (batchsizes?.size) {
                    if (this.machine) {
                        const val = batchsizes.get(this.machine);
                        if (val) {
                            this.suggestedBatchsize = val;
                            found = true;
                        }
                    }
                    if (!this.machine || !this.suggestedBatchsize) {
                        const val = batchsizes.get(null);
                        if (val) {
                            this.suggestedBatchsize = val;
                            found = true;
                        }
                    }
                }
                if (!found) {
                    // try roastersize
                    const rsizes = this.averages.get(this.coffee?._id || this.blend?.label)?.rsizes;
                    if (rsizes?.size) {
                        if (this.machine) {
                            const val = rsizes.get(this.machine);
                            if (val) {
                                this.suggestedBatchsize = val;
                                found = true;
                            }
                        }
                        if (!this.machine || !this.suggestedBatchsize) {
                            const val = rsizes.get(null);
                            if (val) {
                                this.suggestedBatchsize = val;
                                found = true;
                            }
                        }
                    }
                }
                if (!onlyUpdateSuggestions) {
                    // if ((!found || !this.suggestedBatchsize) && this.allowBatchsizeChanges) {
                    //     this.batchsize = null;
                    // }
                    if (this.allowBatchsizeChanges && this.suggestedBatchsize) {
                        this.batchsize = this.suggestedBatchsize;
                        if (updateBatches) {
                            this.updateBatches();
                        }
                    }
                }
            // fall through to set loss as well
            case 'losses':
                if (this.coffee?._id || this.blend?.label) {
                    const losses = this.averages.get(this.coffee?._id || this.blend?.label)?.losses;
                    if (losses) {
                        if (this.machine) {
                            const val = losses.get(this.machine);
                            if (val) {
                                this.suggestedLoss = val;
                                found = true;
                            }
                        }
                        if (!this.machine || !this.suggestedLoss) {
                            const val = losses.get(null);
                            if (val) {
                                this.suggestedLoss = val;
                                found = true;
                            }
                        }
                    }
                }
                if (!found || !this.suggestedLoss) {
                    this.suggestedLoss = this.DEFAULT_LOSS;
                }
                if (this.allowLossChanges && !onlyUpdateSuggestions) {
                    // TODO
                    setTimeout(() => {
                        this.loss = this.suggestedLoss;
                        this.updateValues('loss');
                    }, 100);
                }
                break;
            case 'loss':
                if (this.loss && !onlyUpdateSuggestions) {
                    if (this.unitamount) {
                        if (this.allowWeightChanges || !this.allowAmountChanges) {
                            this.unitweight = this.unitamount * (100 - this.loss) / 100.0;
                        } else if (this.unitweight) {
                            this.unitamount = this.unitweight / (100 - this.loss) * 100.0;
                            this.updateValues('unitamount', false, false);
                            if (updateBatches) {
                                this.updateBatches();
                            }
                        }
                    } else if (this.unitweight) {
                        this.unitamount = this.unitweight / (100 - this.loss) * 100.0;
                        this.updateValues('unitamount', false, false);
                        if (updateBatches) {
                            this.updateBatches();
                        }
                    }
                }
                break;
            case 'unitamount':
                if (this.unitamount && !onlyUpdateSuggestions) {
                    if (updateBatches) {
                        this.updateBatches(false, true);
                    }
                    if (this.loss) {
                        this.unitweight = this.unitamount * (100 - this.loss) / 100.0;
                    } else if (this.unitweight) {
                        if (this.unitweight > this.unitamount) {
                            this.unitweight = this.unitamount;
                        } else {
                            this.loss = 100 - (this.unitweight / this.unitamount * 100);
                        }
                    }
                }
                this.calculateStock();
                break;
            case 'unitweight':
                if (this.unitweight) {
                    if (!this.unitamount && this.loss) {
                        this.unitamount = this.unitweight / (100 - this.loss) * 100.0;
                        this.updateValues('unitamount', false, true);
                        if (updateBatches) {
                            this.updateBatches(false, true);
                        }
                    } else if (this.unitamount) {
                        const wouldBeLoss = 100 - (this.unitweight / this.unitamount * 100);
                        if (wouldBeLoss > 8 && wouldBeLoss < 25) {
                            if (onlyUpdateSuggestions || !this.allowLossChanges) {
                                this.suggestedLoss = wouldBeLoss;
                            } else {
                                this.loss = wouldBeLoss;
                            }
                        } else {
                            this.unitamount = this.unitweight / (100 - this.loss) * 100.0;
                            // this.updateValues('unitamount', false, false);
                            if (updateBatches) {
                                this.updateBatches(false, true);
                            }
                        }
                    }
                }
                break;
            case 'batches':
                // this.allowBatchsizeChanges = !this.batches;
                if (this.batches && !onlyUpdateSuggestions) {
                    if (this.batchsize) {
                        this.unitamount = this.batches * this.batchsize;
                        this.updateValues('unitamount', false, false);
                    } else if (this.unitamount) {
                        this.batchsize = this.unitamount / this.batches;
                    }
                }
                break;
            case 'batchsize':
                if (!onlyUpdateSuggestions) {
                    if (this.unitamount) {
                        if (updateBatches) {
                            this.updateBatches(false, false);
                        }
                    } else if (this.batches) {
                        this.unitamount = this.batches * this.batchsize;
                        this.updateValues('unitamount', false, false);
                    }
                    // if (this.batches) {
                    //     this.unitamount = this.batches * this.batchsize;
                    //     this.updateValues('unitamount');
                    // } else if (this.unitamount) {
                    //     this.updateBatches();
                    // }
                }
                break;
            default:
                this.logger.error(`unknown changedVar ${changedVar} in updateValues`);
                break;
        }
    }

    /**
     * Retrieves (cached) median values for loss, batchsize, etc. per machine.
     * Also gets roast templates.
     * @param {Coffee} [beans] 
     * @param {Blend} [blend] 
     * @param {string }[machine] 
     * @param {boolean} [onlyUpdateSuggestions=false] if true, only suggestions will be calculated, real values will not be changed
     */
    private getAverages(beans?: Coffee, blend?: Blend, machine?: string, onlyUpdateSuggestions = false): void {
        if (beans && blend) {
            this.serverLogService.sendError({ message: 'getAverages: beans and blend both set', details: `beans: ${JSON.stringify(beans)}, blend: ${JSON.stringify(blend)}` }, 'SchedulerComponent.getAverages')
            return;
        }
        if (!beans && !blend) {
            this.suggestedBatchsize = null;
            this.suggestedLoss = null;
            this.templates = [];
            this.filteredTemplates = [];

            return;
        }
        const avgs = this.averages.get(beans?._id?.toString() ?? blend?.label);
        if (avgs) {
            this.updateValues('averages', onlyUpdateSuggestions);
            if (this.coffee || this.blend) {
                this.getRoastTemplates(this.coffee, this.blend, this.machine, !onlyUpdateSuggestions);
                // this.getRoastTemplates(this.coffee, this.blend, this.machine, onlyUpdateSuggestions);
            }
        } else {
            this.loading += 1;
            this.standardService.getRoastAverages(beans, blend, machine)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .pipe(finalize(() => { setTimeout(() => { this.loading -= 1; }, 50); }))
                // .pipe(finalize(() => this.loading -= 1))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            if (response.result) {
                                const machines = Object.getOwnPropertyNames(response.result);
                                const machineLossMap = new Map<string, number>();
                                const machineBatchsizeMap = new Map<string, number>();
                                const machineRsizeMap = new Map<string, number>();
                                for (const machine of machines) {
                                    const mach = machine === '_' ? null : machine;
                                    machineLossMap.set(mach, response.result[machine]?.loss);
                                    machineBatchsizeMap.set(mach, response.result[machine]?.avgbatch);
                                    machineRsizeMap.set(mach, response.result[machine]?.rsize);
                                }
                                this.averages.set((beans?._id ?? blend?.label)?.toString(), { losses: machineLossMap, batchsizes: machineBatchsizeMap, rsizes: machineRsizeMap });
                                this.updateValues('averages', onlyUpdateSuggestions);
                                if (this.coffee || this.blend) {
                                    this.getRoastTemplates(this.coffee, this.blend, this.machine, !onlyUpdateSuggestions);
                                    // this.getRoastTemplates(this.coffee, this.blend, this.machine, onlyUpdateSuggestions);
                                }
                            }
                        } else {
                            this.utils.handleError('error retrieving information', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving information', error);
                    }
                });
        }
    }

    // /**
    //  * Pre-select latest template
    //  * @param {boolean} [onlyUpdateSuggestions=false] if true, only suggestions will be calculated, real values will not be changed
    //  */
    // private selectLatestTemplate(onlyUpdateSuggestions: boolean = false): void {
    //     if (this.templates?.length) {
    //         let latestIdx = 0;
    //         let latestDate = this.templates[0].date;
    //         for (let t = 0; t < this.templates.length; t++) {
    //             const template = this.templates[t];
    //             if (template.date > latestDate) {
    //                 latestDate = template.date;
    //                 latestIdx = t;
    //             }
    //         }
    //         this.template = this.templates[latestIdx];
    //         this.updateValues('template', onlyUpdateSuggestions);
    //     } else {
    //         this.template = undefined;
    //     }
    //     this.updateTitle();
    // }

    /**
     * Pre-select a fitting template. Only if coffee or blend fits and 
     * machine fits (or not set).
     * @param {boolean} [onlyUpdateSuggestions=false] if true, only suggestions will be calculated, real values will not be changed
     */
    private selectFittingTemplate(onlyUpdateSuggestions = false): void {
        if (this.template) {
            return;
        }
        if (this.templates?.length) {
            for (const template of this.templates) {
                if ((template.coffee === this.coffee?._id?.toString() || template.blend === this.blend?._id?.toString())
                    && (!template.machine || !this.machine || template.machine === this.machine)) {
                    this.template = template;
                    break;
                }
            }
            this.updateValues('template', onlyUpdateSuggestions || this.currentlySettingItem);
        } else {
            this.template = undefined;
        }
        // done in updateValues:
        // this.updateTitle();
    }

    /**
     * Retrieves (and caches) roast templates.
     * Exactly one of beans or blend must be set.
     * @param {Coffee} [beans] coffee
     * @param {Blend} [blend] blend
     * @param {string} [machine] optional machine
     * @param {boolean} [selectLatestTemplate=false] if true, selectFittingTemplate will be called
     */
    private getRoastTemplates(beans?: Coffee, blend?: Blend, machine?: string /*, onlyUpdateSuggestions: boolean = false*/, selectLatestTemplate = false): void {
        const templKey = beans?._id?.toString() ?? blend?.label ?? blend?._id?.toString();
        if (this.alreadyLoadedTemplates.has(templKey)) {
            const templ = this.templateMap.get(templKey);
            if (templ) {
                if (machine) {
                    this.templates = templ.filter(t => !t.machine || !machine || t.machine === machine);
                } else {
                    this.templates = templ;
                }
                if (this.mustIncludeTemplate && !this.templates.map(t => t.roast_id).includes(this.mustIncludeTemplate.roast_id)) {
                    this.templates.unshift(this.mustIncludeTemplate);
                }
                this.filteredTemplates = this.templates.slice();
                if (selectLatestTemplate) {
                    this.selectFittingTemplate();
                }
            }
        } else {
            this.loading += 1;
            this.standardService.getRoastTemplates(beans, blend)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .pipe(finalize(() => { setTimeout(() => { this.loading -= 1; }, 50); }))
                // .pipe(finalize(() => this.loading -= 1))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            if (response.result) {
                                if (this.templateMap.has(templKey)) {
                                    // // need to ignore duplicates
                                    // this.templateMap.set(templKey, [...(this.templateMap.get(templKey) ?? []), ...response.result]);
                                    const tmplts = response.result;
                                    const existIds = tmplts.map(tmpl => tmpl.roast_id);
                                    const loadedTemplates = this.templateMap.get(templKey);
                                    for (const tmplt of loadedTemplates) {
                                        if (!existIds.includes(tmplt.roast_id)) {
                                            tmplts.unshift(tmplt);
                                        }
                                    }
                                    this.templateMap.set(templKey, tmplts);
                                } else {
                                    this.templateMap.set(templKey, response.result);
                                }
                                if (machine) {
                                    this.templates = response.result.filter(t => !t.machine || t.machine === machine);
                                } else {
                                    this.templates = response.result;
                                }
                                if (this.mustIncludeTemplate) {
                                    this.templates.unshift(this.mustIncludeTemplate);
                                }
                                this.filteredTemplates = this.templates.slice();
                                if (selectLatestTemplate) {
                                    this.selectFittingTemplate();
                                }
                                // this.selectFittingTemplate(true);
                            }
                            this.alreadyLoadedTemplates.add(templKey);
                        } else {
                            this.utils.handleError('error retrieving information', response.error);
                            if (!this.templates?.length) {
                                this.templates = [];
                            }
                        }
                        this.filteredTemplates = this.templates.slice();
                    },
                    error: error => {
                        this.utils.handleError('error retrieving information', error);
                    }
                });
        }
    }

    protected checkChangesUnits(parent: unknown, variable: ChangedVar, oldValue: number, newValueStr: string, toUnit: boolean, digits = 3): void {
        // if (!newValueStr) {
        //     parent[variable] = undefined;
        //     return;    
        // }
        parent[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val } = this.utils.checkChangedValue(oldValue * (!toUnit ? this.utils.getUnitFactor(this.mainUnit) : 1), newValueStr, digits, false, true);
            parent[variable] = val / (!toUnit ? this.utils.getUnitFactor(this.mainUnit) : 1);
            if (variable === 'batchsize') {
                this.allowBatchsizeChanges = false;
            } else if (variable === 'unitamount') {
                this.allowAmountChanges = false;
            }
            this.updateValues(variable);
            this.waitingForChanges = false;
        });
    }

    protected checkChanges(variable: ChangedVar, oldValue: number, newValueStr: string, digits = 3): void {
        this[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val } = this.utils.checkChangedValue(oldValue, newValueStr, digits, false, true);
            this[variable] = val;
            this.updateValues(variable);
            this.waitingForChanges = false;
        });
    }

    /**
     * Adjust number of batches such that the amount is reached / exceeded.
     * (called on user's click on suggestion)
     */
    protected noMissingAmount() {
        if (this.unitamount && this.batchsize) {
            // calculate fitting batch number
            this.batches = Math.ceil(Math.round(this.unitamount * 1000 / this.batchsize) / 1000);
            this.updateValues('batches');
        } else if (this.unitamount && this.batches) {
            // calculate fitting batch size
            this.batchsize = this.unitamount / this.batches;
            this.updateValues('batchsize');
        }
    }

    /**
     * Adjust batch size such that there are no left over green beans.
     * (called on user's click on suggestion)
     */
    protected noLeftOver() {
        if (this.unitamount && this.batchsize) {
            // calculate fitting batch size
            this.batchsize = this.unitamount / this.batches;
            this.updateValues('batchsize', false, false);
        }
    }

    /**
     * Use suggested loss value.
     * (called on user's click on suggestion)
     */
    protected useLossSuggestion() {
        if (this.suggestedLoss) {
            this.loss = this.suggestedLoss;
            this.updateValues('loss');
        }
    }

    // /**
    //  * Use suggested amount value
    //  */
    // protected useAmountSuggestion() {
    //     if (this.suggestedAmount) {
    //         this.unitamount = this.suggestedAmount;
    //         this.updateValues('unitamount');
    //     }
    // }

    /**
     * Use batches * batchsize as amount.
     * (called on user's click on suggestion)
     */
    protected useAmountSuggestion() {
        if (this.batches * this.batchsize) {
            this.unitamount = this.batches * this.batchsize;
            this.updateValues('unitamount');
        }
    }

    /**
     * Use suggested batch size value.
     * (called on user's click on suggestion)
     */
    protected useBatchsizeSuggestion() {
        if (this.suggestedBatchsize) {
            this.batchsize = this.suggestedBatchsize;
            this.updateValues('batchsize');
        }
    }

    protected isOffFromSuggestion(val: number, suggestedVal: number): boolean {
        if (!val && !suggestedVal) {
            return false;
        }
        if (val && suggestedVal) {
            return Math.abs(val - suggestedVal) / suggestedVal > this.SUGGESTION_OFF_THRESHOLD;
        }
        return true;
    }

    // private setCoffee(item: RoastScheduledItem, hr_id: string): void {
    //     this.standardService.getOne<Coffee>('coffees', hr_id)
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     item.coffee = response.result;
    //                 } else {
    //                     // TODO could probably fail silently
    //                     this.utils.handleError('error retrieving information', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 this.utils.handleError('error retrieving information', error);
    //             }
    //         });
    // }

    // private setBlend(item: RoastScheduledItem, label: string): void {
    //     this.standardService.getOne<Blend>('blends', label)
    //         .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
    //         .subscribe({
    //             next: response => {
    //                 if (response.success === true) {
    //                     item.blend = response.result;
    //                 } else {
    //                     // TODO could probably fail silently
    //                     this.utils.handleError('error retrieving information', response.error);
    //                 }
    //             },
    //             error: error => {
    //                 this.utils.handleError('error retrieving information', error);
    //             }
    //         });
    // }

    private setCoffeeBlendData(schedules: RoastSchedule[], coffMap: Map<string, Coffee>, blendMap: Map<string, Coffee>): void {
        for (let s = 0; s < schedules?.length; s++) {
            const schedule = schedules[s];
            for (let i = 0; i < schedule.items?.length; i++) {
                const item = schedule.items[i];
                if (item.coffee?.hr_id ?? item.coffee) {
                    const cof = coffMap?.get(item.coffee.hr_id ?? item.coffee.toString());
                    if (cof) {
                        item.coffee = cof;
                    }
                }
                if (item.blend?.hr_id ?? item.blend) {
                    const blend = blendMap?.get(item.blend.hr_id ?? item.blend.toString());
                    if (blend) {
                        item.blend = blend;
                    }
                }
            }
        }
    }

    public loadDataForSchedules(schedules: RoastSchedule[]) {
        const coffeesToSet = new Set<number>();
        const blendsToSet = new Set<number>();

        // set some data and collect the coffees / blends that need to be retrieved
        for (let s = 0; s < schedules?.length; s++) {
            const schedule = schedules[s];
            for (let i = 0; i < schedule.items?.length; i++) {
                const item = schedule.items[i];
                const templKey = item.coffee?._id ?? item.blend?.label ?? item.blend?._id ?? item.blend;
                if (item.coffee) {
                    const cof = this.coffees?.filter(cof => cof.hr_id === (item.coffee.hr_id ?? item.coffee))?.[0];
                    if (!cof) {
                        const id = Number.parseInt((item.coffee.hr_id ?? item.coffee.toString()).substring(1), 10);
                        if (Number.isFinite(id)) {
                            coffeesToSet.add(id);
                        }
                        // this.setCoffee(item, (item.coffee.hr_id ?? item.coffee).toString());
                    } else {
                        item.coffee = cof;
                    }
                }
                if (item.blend) {
                    const blnd = this.blends?.filter(blend => (blend.hr_id === (item.blend?.hr_id ?? item.blend)) || (blend.label === (item.blend?.label ?? item.blend)))?.[0];
                    if (!blnd) {
                        // assume here that hr_id will always be set (and not label only)
                        const id = Number.parseInt((item.blend.hr_id ?? item.blend.toString()).substring(1), 10);
                        if (Number.isFinite(id)) {
                            blendsToSet.add(id);
                        }
                        // this.setBlend(item, (item.blend?.label ?? item.blend).toString());
                    } else {
                        item.blend = blnd;
                    }
                }
                if (item.location) {
                    item.location = this.stores?.filter(store => store.hr_id === (item.location.hr_id ?? item.location).toString())?.[0];
                }

                this.templates = this.templateMap.get(templKey?.toString()) ?? [];
                if (item.template) {
                    const tmplt = this.templates?.filter(tmplt => tmplt['roast_id'] === item.template['roast_id'])?.[0];
                    if (tmplt) {
                        item.template = tmplt;
                    } else {
                        // template was loaded separately; add and use this
                        item.template = Object.assign({}, item.template);
                        this.templates.push(item.template);
                        const tmapentries = this.templateMap.get(templKey?.toString());
                        if (tmapentries) {
                            tmapentries.push(item.template);
                        } else {
                            this.templateMap.set(templKey?.toString(), [item.template]);
                        }
                    }
                }
                this.filteredTemplates = this.templates?.slice();

                if (item.user) {
                    const usr = this.users.filter(user => user._id === item.user)?.[0];
                    if (usr) {
                        item.nickname = usr.nickname;
                    }
                }
            }
        }

        if (coffeesToSet.size || blendsToSet.size) {
            // retrieve coffees and blends and store them in a map
            const coffeeIdToData = new Map<string, Coffee>();
            const blendIdToData = new Map<string, Blend>();
            const obsvbls = [];
            if (coffeesToSet.size) {
                obsvbls.push(this.standardService.getAll<Coffee>('coffees', { ID: { vals: Array.from(coffeesToSet) }, showHidden: 'true' }));
            }
            if (blendsToSet.size) {
                obsvbls.push(this.standardService.getAll<Blend>('blends', { ID: { vals: Array.from(blendsToSet) }, showHidden: 'true' }));
            }

            forkJoin(obsvbls as Observable<{ success: boolean; result: Coffee[] | Blend[]; error: string; }>[])
                .subscribe({
                    next: responses => {
                        const cofResponse = coffeesToSet.size ? responses[0] : undefined;
                        if (cofResponse) {
                            if (cofResponse.success === true) {
                                for (const coff of cofResponse.result) {
                                    coffeeIdToData.set(coff.hr_id, coff);
                                }
                            } else {
                                // TODO could probably fail silently
                                this.utils.handleError('error retrieving information', cofResponse.error);
                            }
                        }
                        const blendResponse = coffeesToSet.size ? responses[1] : (blendsToSet.size ? responses[0] : undefined);
                        if (blendResponse) {
                            if (blendResponse.success === true) {
                                for (const blend of blendResponse.result) {
                                    blendIdToData.set(blend.hr_id, blend);
                                }
                            } else {
                                // TODO could probably fail silently
                                this.utils.handleError('error retrieving information', cofResponse.error);
                            }
                        }
                        if (coffeeIdToData.size || blendIdToData.size) {
                            // set the coffee data
                            this.setCoffeeBlendData(schedules, coffeeIdToData, blendIdToData);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error retrieving information', error);
                    }
                });
            // this.setCoffee(schedules[key.scheduleIdx].items[key.itemIdx], value);

            // blendsToSet.forEach((value: string, key: { scheduleIdx: number, itemIdx: number }) => {
            //     this.setBlend(schedules[key.scheduleIdx].items[key.itemIdx], value);
            // });
        }
    }

    public edit(item: RoastScheduledItem, readonly?: boolean, favoritesLine?: number): void {
        // no; we want to display the data
        // if (this.readOnly) { return; }

        this.itemReadOnly = readonly || item?.synced || item?.date < this.today;
        this.currentlySettingItem = true;
        this.editModeItem = item;
        this.editModeItemOriginal = Object.assign({}, item);
        this.favoritesLine = favoritesLine;

        if (item) {
            this.batches = item.count;
            this.batchsize = item.amount;
            this.loss = item.loss;
            this.unitamount = this.batches * this.batchsize;
            this.unitweight = this.unitamount * (100 - this.loss) / 100.0;

            this.allowAmountChanges = true;
            this.allowLossChanges = true;
            this.allowBatchsizeChanges = false;
            this.allowWeightChanges = false;

            if (item.coffee) {
                this.coffee = this.coffees.filter(cof => cof.hr_id === (item.coffee.hr_id ?? item.coffee))?.[0];
                if (!this.filteredCoffees.some(cof => cof.hr_id === (item.coffee.hr_id ?? item.coffee))) {
                    if (!this.coffees.some(cof => cof.hr_id === (item.coffee.hr_id ?? item.coffee))) {
                        this.coffees.push(item.coffee);
                        // this.additionalCoffee = this.coffee;
                    }
                    this.filteredCoffees.push(item.coffee);
                    this.coffee = item.coffee;
                }
            } else {
                this.coffee = undefined;
            }
            if (item.blend) {
                this.blend = this.blends.filter(blend => (blend.hr_id === (item.blend.hr_id ?? item.blend)) || (blend.label === (item.blend.label ?? item.blend)))?.[0];
                if (!this.filteredBlends.some(blend => blend.label === (item.blend.label ?? item.blend))) {
                    if (!this.blends.some(blend => blend.label === (item.blend.label ?? item.blend))) {
                        this.blends.push(item.blend);
                        // this.additionalBlend = this.blend;
                    }
                    this.filteredBlends.push(item.blend);
                    this.blend = item.blend;
                }
            } else {
                this.blend = undefined;
            }
            this.machine = item.machine;
            if (this.machineFilter) {
                if (this.machine && !this.machines.includes(this.machine)) {
                    this.machines.push(this.machine);
                    this.machineFilter.allOptions = this.machines ?? [];
                    // this.machineFilter.allOptions = [null, ...this.machines ?? []];
                }
                this.machineFilter.setValues([this.machine]);
            }
            if (item.location) {
                this.store = this.stores.filter(store => store.hr_id === (item.location.hr_id ?? item.location).toString())?.[0];
            } else {
                this.store = undefined;
            }
            if (this.storeFilter) {
                this.storeFilter?.setValues([this.store?.label]);
            }
            const templateChanged = this.template && this.template.roast_id !== item?.template?.roast_id;
            this.template = item.template;
            if (item.template) {
                // the filteredTemplates might be replaced by server response later
                // make sure item.template is in this list
                this.mustIncludeTemplate = item.template;
                if (!this.templates.some(tmplt => item.template.roast_id === tmplt.roast_id)) {
                    if (!this.filteredTemplates.some(tmplt => this.template.roast_id === tmplt.roast_id)) {
                        this.filteredTemplates.unshift(item.template);
                    }
                    this.templates.unshift(item.template);
                }
            }

            if (item.user) {
                this.user = this.users.filter(user => user._id === item.user)?.[0];
            } else {
                this.user = undefined;
            }
            if (this.userFilter) {
                this.userFilter.setValues([this.user?.nickname]);
            }
            this.notes = item.note;
            this.getAverages(this.coffee, this.blend, this.machine, true);
            if (templateChanged) {
                this.updateValues('template', true);
            }
            // do this at the end such that it is not replaced
            this.title = item.title;

            // TODO needed? it breaks the adding of item.coffee to the filteredCoffees list
            // if the item.coffee is not in the coffees or filteredCoffees list
            // needed since the stock needs to be updated to the newly selected beans
            // => copied some code that adds item.coffee to the list
            if (!this.loading) { // otherwise will be called automatically later anyway
                this.calculateStock(true);
            }
        }

        // TODO remove hack: wait a bit such that, e.g. machineChanged, called
        // as a result of setting the values from the editied item still gets the truthy value
        setTimeout(() => {
            this.currentlySettingItem = false;
        }, 250);
    }

    protected save(): void {
        if (this.readOnly || !this.editModeItem) { return; }

        let stockChanged = false;
        if (this.includePlannedInStock) {
            if (this.editModeItem.amount !== this.batchsize
                || this.editModeItem.count !== this.batches
                || this.editModeItem.coffee !== this.coffee
                || this.editModeItem.blend !== this.blend
                || this.editModeItem.location !== this.store) {
                if (this.editModeItem.coffee) {
                    this.changeBeansStock([{ coffee: this.editModeItem.coffee.hr_id, stock: [{ location: this.editModeItem.location.hr_id, amount: this.editModeItem.count * this.editModeItem.amount }]} ], true);
                } else if (this.editModeItem.blend) {
                    for (const ing of this.editModeItem.blend.ingredients) {
                        this.changeBeansStock([{ coffee: ing.coffee.hr_id, stock: [{ location: this.editModeItem.location.hr_id, amount: (ing.ratio * this.editModeItem.count * this.editModeItem.amount) || 0 }] }], true);
                    }
                }
                stockChanged = true;
            } 
        }

        this.editModeItem.amount = this.batchsize;
        this.editModeItem.loss = this.loss;
        this.editModeItem.coffee = this.coffee;
        this.editModeItem.blend = this.blend;
        this.editModeItem.count = this.batches;
        this.editModeItem.location = this.store;
        this.editModeItem.machine = this.machine;
        this.editModeItem.title = this.title;
        this.editModeItem.template = this.template;
        // don't fall back to currentUser
        this.editModeItem.user = this.user?._id;
        // this.editModeItem.nickname = this.user?.nickname;
        this.editModeItem.note = this.notes;

        // TODO maybe doesn't always work since editModeItemOriginal has populated coffee etc. set!
        if (JSON.stringify(this.editModeItem) !== JSON.stringify(this.editModeItemOriginal)) {
            this.isUpdating = true;
            this.saveEdit.emit({
                item: Object.assign({}, this.editModeItemOriginal),
                cb: (success: boolean) => {
                    // (success is always true)
                    if (success) {
                        this.editModeItemOriginal = Object.assign({}, this.editModeItem);
                        this.mustIncludeTemplate = undefined;
                    }
                    if (this.favoritesLine >= 0) {
                        // updateFinished isn't called when it is a favorite item
                        this.updateFinished();
                    }
                    if (stockChanged && this.includePlannedInStock) {
                        if (this.coffee) {
                            this.changeBeansStock([{ coffee: this.coffee.hr_id, stock: [{ location: this.store.hr_id, amount: this.batches * this.batchsize }] }], false);
                        } else if (this.blend) {
                            for (const ing of this.blend.ingredients) {
                                this.changeBeansStock([{ coffee: ing.coffee.hr_id, stock: [{ location: this.store.hr_id, amount: (ing.ratio * this.batches * this.batchsize) || 0 }] }], false);
                            }
                        }

                        this.calculateStock();
                    }
                }, favoritesLine: this.favoritesLine,
            });
        } else {
            this.cancelEditMode();
        }
    }

    public updateFinished(): void {
        this.isUpdating = false;
    }

    protected cancelEditMode(): void {
        this.editModeItem = undefined;
        this.favoritesLine = undefined;
        this.mustIncludeTemplate = undefined;
        this.cancelEdit.emit();
    }

    protected add(date?: string): void {
        if (this.readOnly) { return; }

        // this explicitly clones only specific attributes
        // especially not .roasts or .synced
        const item = new RoastScheduledItem();
        item.amount = this.batchsize;
        item.coffee = this.coffee;
        item.blend = this.blend;
        item.count = this.batches;
        item.loss = this.loss;
        item.location = this.store;
        item.machine = this.machine;
        item.title = this.title;
        item.template = this.template;
        if (this.users?.length === 1) {
            // artisan expects current user if no other users possible
            item.user = this.users[0]?._id ?? this.currentUser.user_id;
            item.nickname = this.users[0]?.nickname ?? this.currentUser.nickname;
        } else {
            // don't fall back to currentUser
            item.user = this.user?._id;
            item.nickname = this.user?.nickname;
        }
        item.note = this.notes;
        item.isPostBlend = this.isPostBlend;

        if (!item.amount) {
            this.utils.handleError(undefined, `Please enter a value for {{missingField}}#${this.tr.anslate('Batch size')}`);
        } else if (!item.count) {
            this.utils.handleError(undefined, `Please enter a value for {{missingField}}#${this.tr.anslate('Batches')}`);
        } else if (!item.location) {
            this.utils.handleError(undefined, `Please enter a value for {{missingField}}#${this.tr.anslate('Store')}`);
        } else if (!item.title) {
            this.utils.handleError(undefined, `Please enter a value for {{missingField}}#${this.tr.anslate('Title')}`);
        } else if (!item.coffee && !item.blend) {
            this.utils.handleError(undefined, `Please enter a value for {{missingField}}#${this.tr.anslate('Beans')} / ${this.tr.anslate('Blends')}`);
        } else {
            this.newItem.emit({ item, date });
            if (this.includePlannedInStock) {
                this.updatePlannedInStock(item);
                this.calculateStock();
            }
        }
    }

    private updatePlannedInStock(item: RoastScheduledItem, add = false) {
        if (!item) {
            console.log('ITEM empty');
            return;
        }
        if (item.coffee) {
            this.changeBeansStock([{ coffee: item.coffee.hr_id, stock: [{ location: item.location.hr_id, amount: item.count * item.amount }] }], add);
        } else if (item.blend) {
            for (let i = 0; i < item.blend.ingredients?.length; i++) {
                const ing = item.blend.ingredients[i];
                if (!ing?.coffee) {
                    continue;
                }
                this.changeBeansStock([{ coffee: ing.coffee.hr_id, stock: [{ location: item.location.hr_id, amount: item.count * item.amount * ing.ratio }] }], add);
            }
        }
    }

    public addCurrent(date: string): void {
        this.add(date);
    }

    public itemAdded(item: RoastScheduledItem): void {
        if (this.includePlannedInStock) {
            this.updatePlannedInStock(item, false);
            this.calculateStock();
        }
    }

    public itemDeleted(item: RoastScheduledItem): void {
        if (this.includePlannedInStock) {
            this.updatePlannedInStock(item, true);
            this.calculateStock();
        }
    }

    private renameMachineNames(replacements: { val: string, replaceWith: string }[]): void {
        if (this.readOnly) { return; }

        replacements = replacements?.filter(r => r.val !== r.replaceWith) ?? [];
        if (replacements?.length) {
            this.logger.debug('replacing: ' + JSON.stringify(replacements));
            this.remindersService.renameMachineNames(replacements)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: () => {
                        this.alertService.success('Successfully updated');
                        this.getAllMachines();
                        this.reloadSchedule.emit();
                    },
                    error: error => {
                        this.utils.handleError('Rename machine names', error);
                    }
                });
        } else {
            this.alertService.success('Nothing to change');
        }
    }

    protected openRenameMachineDialog(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(RenameMachineNamesDialogComponent, {
            closeOnNavigation: true,
            data: { machines: this.machines.map(m => ({ label: m })) },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let anychanges = false;
                for (const rep of result) {
                    if (rep.val !== rep.replaceWith) {
                        anychanges = true;
                        break;
                    }
                }
                if (anychanges) {
                    this.renameMachineNames(result);
                } else {
                    this.alertService.success('Nothing to change');
                }
            }
        });
    }

    protected openAddMachineDialog(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(TextinputDialogComponent, {
            closeOnNavigation: true,
            data: { title: `+ ${this.tr.anslate('Machine')}`, placeholder: this.tr.anslate('Machine') },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && !this.machines.includes(result)) {
                // add new machine in the settings where it can be found by the getAllMachines mechanism
                this.standardService.addSettingArray(Enumerations.SETTINGS.additional_machines, result)
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .subscribe({
                        next: () => {
                            this.alertService.success('Successfully updated');
                            if (!this.machines) {
                                this.machines = [null, result];
                            } else {
                                this.machines = [...this.machines, result];
                            }
                            this.filteredMachines = this.machines.slice();
                        },
                        error: error => {
                            this.utils.handleError('Machine', error);
                        }
                    });
            }
        });
    }

    showTitleInputIcon(show: boolean) {
        if (show) {
            this.showTII = true;
        } else {
            setTimeout(() => {
                this.showTII = false;
            }, 1000);
        }
    }

    protected openTitleDialog(): void {
        const dialogRef = this.dialog.open(TextinputDialogComponent, {
            closeOnNavigation: true,
            width: '85%',
            data: { placeholder: this.tr.anslate('Title'), text: this.title },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.title = result.trim();
            }
        });
    }

    protected openTemplateSearchDialog(): void {
        const dialogRef = this.dialog.open(TemplateSearchDialogComponent, {
            closeOnNavigation: true,
            data: {
                currentUser: this.currentUser,
                coffees: this.coffees,
                blends: this.blends,
                machines: this.machines,
            },
        });

        dialogRef.afterClosed().subscribe(template => {
            if (template) {
                this.template = template;
                if (!this.templates.some(tmplt => template.roast_id === tmplt.roast_id)) {
                    if (!this.filteredTemplates.some(tmplt => template.roast_id === tmplt.roast_id)) {
                        this.filteredTemplates.unshift(template);
                    }
                    this.templates.unshift(template);
                }
                this.templateChanged();
            }
        });
    }

    // TODO same code as in template-search-dialog.component.ts
    protected openBeansSearchDialog(searchCoffees = true, searchBlends = true): void {
         
        const dialogRef = this.dialog.open<BeansSearchDialogComponent, { coffees: Coffee[], blends: Blend[], searchBlends?: boolean, searchCoffees?: boolean }, { isBlend: false, item: Coffee } | { isBlend: true, item: Blend }>(BeansSearchDialogComponent, {
            closeOnNavigation: true,
            data: {
                coffees: this.coffees,
                blends: this.blends,
                searchCoffees,
                searchBlends,
            },
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res?.item) {
                if (res.isBlend) {
                    this.blend = res.item;
                    this.coffee = undefined;
                    let found = false;
                    for (const blend of this.filteredBlends) {
                        if (blend.label === this.blend.label) {
                            found = true;
                            this.blend = blend;
                            break;
                        }
                    }
                    if (!found) {
                        if (!this.blends.some(blend => blend.label === this.blend.label)) {
                            this.blends.push(this.blend);
                        }
                        this.filteredBlends.push(this.blend);
                    }
                    this.utils.populateCoffees(this.blend, this.coffees);
                    this.blend.organic = this.utils.isOrganicBlend(this.blend);
                    this.blend.blendinfo = this.utils.getBlendStr(this.blend, ', ', true);
                    this.beansChanged({ value: { _id: this.blend._id } }, true, true);
                } else if (res.isBlend === false) {
                    this.coffee = res.item;
                    this.blend = undefined;
                    this.coffee.stock?.forEach(stock => {
                        // convert from artisan representation
                        stock.location = { _id: stock.location_id, label: stock.location_label };
                        delete stock.location_id;
                        delete stock.location_label;
                    });
                    let found = false;
                    for (const cof of this.filteredCoffees) {
                        if (cof.hr_id === this.coffee.hr_id) {
                            found = true;
                            this.coffee = cof;
                            break;
                        }
                    }
                    if (!found) {
                        if (!this.coffees.some(cof => cof.hr_id === this.coffee.hr_id)) {
                            this.coffees.push(this.coffee);
                        }
                        this.filteredCoffees.push(this.coffee);
                    }
                    this.beansChanged({ value: { _id: this.coffee._id } }, false, true);
                }
            }
        });
    }

    // addTemplatesToOptions() {
    //     const templ = this.templateMap.get(this.coffee?._id?.toString() ?? this.blend?.label ?? this.blend?._id?.toString());
    //     if (templ?.length > (this.templates?.length ?? 0)) {
    //         this.templates = templ;
    //     } else {
    //         let tmp = [];
    //         for (const [, value] of this.templateMap) {
    //             tmp = tmp.concat(value);
    //         }
    //         this.templates = tmp;
    //     }
    //     this.filteredTemplates = this.templates.slice();
    //     this.templateSelect?.open();
    // }

    // TODO doesn't work if two users have same nickname
    selectUser(nickname: string) {
        for (const user of this.users) {
            if (user.nickname === nickname) {
                this.user = user;
                return;
            }
        }
        this.user = undefined;
    }

    protected changeSubMainUnit(): void {
        switch (this.mainUnit) {
            case 'lb':
                this.mainUnit = 'oz';
                this.weightFormat = '1.0-0';
                break;
            case 'oz':
                this.mainUnit = 'lb';
                this.weightFormat = '1.0-3';
                break;
            case 'kg':
                this.mainUnit = 'g';
                this.weightFormat = '1.0-0';
                break;
            case 'g':
                this.mainUnit = 'kg';
                this.weightFormat = '1.0-3';
                break;
            default:
                this.mainUnit = 'kg';
                this.weightFormat = '1.0-3';
        }
        if (!this.readOnly) {
            this.currentUser = this.standardService.setSetting(Enumerations.SETTINGS.schedule_unit, this.mainUnit, this.ngUnsubscribe) ?? this.currentUser;
        }
    }
}
